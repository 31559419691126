<template>
  <section id="main">
    <Header />
    <Navigation />
    <div class="wrap">
      <div class="main">
        <div class="left">
          <h1 class="title">
            <span> АВТОШКОЛА В РОСТОВЕ </span>
            Начни обучение бесплатно, а деньги заплатишь, если понравится
          </h1>
          <div class="cards">
            <div class="card">
              <div class="img">
                <svg
                  width="61"
                  height="61"
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6001 32.6001H20.1001"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.3 43.7001H7V21.6001H31.7"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.3999 14.4V10.8"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.5 14.4V10.8"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.9 27.4C14.5076 27.4 15 26.9075 15 26.2999C15 25.6924 14.5076 25.2 13.9 25.2C13.2925 25.2 12.8 25.6924 12.8 26.2999C12.8 26.9075 13.2925 27.4 13.9 27.4Z"
                    fill="#37307D"
                  />
                  <path
                    d="M19.9 27.4C20.5076 27.4 21 26.9075 21 26.2999C21 25.6924 20.5076 25.2 19.9 25.2C19.2925 25.2 18.8 25.6924 18.8 26.2999C18.8 26.9075 19.2925 27.4 19.9 27.4Z"
                    fill="#37307D"
                  />
                  <path
                    d="M25.7999 27.4C26.4075 27.4 26.9 26.9075 26.9 26.2999C26.9 25.6924 26.4075 25.2 25.7999 25.2C25.1924 25.2 24.7 25.6924 24.7 26.2999C24.7 26.9075 25.1924 27.4 25.7999 27.4Z"
                    fill="#37307D"
                  />
                  <path
                    d="M46.9001 44.3001C47.5076 44.3001 48 43.8076 48 43.2001C48 42.5926 47.5076 42.1001 46.9001 42.1001C46.2925 42.1001 45.8 42.5926 45.8 43.2001C45.8 43.8076 46.2925 44.3001 46.9001 44.3001Z"
                    fill="#37307D"
                  />
                  <path
                    d="M13.9 40.1001C14.5076 40.1001 15 39.6077 15 39.0001C15 38.3926 14.5076 37.9001 13.9 37.9001C13.2925 37.9001 12.8 38.3926 12.8 39.0001C12.8 39.6077 13.2925 40.1001 13.9 40.1001Z"
                    fill="#37307D"
                  />
                  <path
                    d="M19.9 40.1001C20.5076 40.1001 21 39.6077 21 39.0001C21 38.3926 20.5076 37.9001 19.9 37.9001C19.2925 37.9001 18.8 38.3926 18.8 39.0001C18.8 39.6077 19.2925 40.1001 19.9 40.1001Z"
                    fill="#37307D"
                  />
                  <path
                    d="M40.8001 50.2001C48.0903 50.2001 54.0001 44.2902 54.0001 37C54.0001 29.7099 48.0903 23.8 40.8001 23.8C33.5099 23.8 27.6001 29.7099 27.6001 37C27.6001 44.2902 33.5099 50.2001 40.8001 50.2001Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M39.1001 29.6001L40.8001 37.0001L44.0001 40.4001"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 21.6001V16.6001C7 15.4001 8 14.4001 9.2 14.4001H38.6C39.8 14.4001 40.8 15.4001 40.8 16.6001V19.6001"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="txt">Права всего за 39 дней</div>
            </div>
            <div class="card">
              <div class="img">
                <svg
                  width="61"
                  height="61"
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M39.0599 19.3H31.3599C31.3599 18.24 31.7899 17.27 32.4799 16.58C33.1699 15.88 34.1299 15.45 35.1999 15.45C37.3399 15.45 39.0599 17.18 39.0599 19.3Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.2402 44.4199C29.5502 45.1199 28.5802 45.5499 27.5202 45.5499C25.3902 45.5499 23.6702 43.83 23.6702 41.7H13.2002V19.3C13.2002 18.24 13.6302 17.27 14.3302 16.58C15.0202 15.88 15.9802 15.45 17.0502 15.45H35.2102C34.1402 15.45 33.1802 15.88 32.4902 16.58C31.8002 17.27 31.3702 18.24 31.3702 19.3V25.87"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.51 45.5499H9.35C7.22 45.5499 5.5 43.83 5.5 41.7H23.66C23.66 43.82 25.39 45.5499 27.51 45.5499Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.3999 21.99H24.1599"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.6201 29.45H20.8901"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.6201 34.75H19.6101"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M39.69 37.8901C38.52 39.1001 37.28 39.8901 35.64 39.8901C32.8 39.8901 30.5 37.5901 30.5 34.7501C30.5 31.9101 32.8 29.6101 35.64 29.6101C38.48 29.6101 40.1 31.9701 42.27 34.7501C44.44 37.5301 46.06 39.8901 48.9 39.8901C51.74 39.8901 54.04 37.5901 54.04 34.7501C54.04 31.9101 51.74 29.6101 48.9 29.6101C47.21 29.6101 45.95 30.4501 44.74 31.7201"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="txt">Бессрочная лицензия и аккредитация ГИБДД</div>
            </div>
            <div class="card">
              <div class="img">
                <svg
                  width="61"
                  height="60"
                  viewBox="0 0 61 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34.6002 30.0001L30.4002 23.6001C30.0002 23.0001 29.3002 22.6001 28.6002 22.6001H16.8002C16.1002 22.6001 15.4002 23.0001 15.0002 23.6001L10.7002 30.0001"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M38.3 44.6H7V33.4C7 31.5 8.5 30 10.4 30H35C36.9 30 38.4 31.5 38.4 33.4V44.6H38.3Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.7 49.0001H33.2C32.5 49.0001 31.9 48.5001 31.8 47.8001L31.5 44.6001H38.4L38.1 47.8001C37.9 48.5001 37.4 49.0001 36.7 49.0001Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.2 49.0001H8.7C8 49.0001 7.4 48.5001 7.3 47.8001L7 44.6001H13.9L13.6 47.8001C13.5 48.5001 12.9 49.0001 12.2 49.0001Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M29.0998 44.5999L25.8998 39.3999H22.6998H19.3998L16.2998 44.5999"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.9002 37.7999C32.9002 38.4075 32.4077 38.9 31.8002 38.9C31.1927 38.9 30.7002 38.4075 30.7002 37.7999C30.7002 37.1924 31.1927 36.7 31.8002 36.7C32.4077 36.7 32.9002 37.1924 32.9002 37.7999Z"
                    fill="#37307D"
                    stroke="#37307D"
                  />
                  <path
                    d="M47.7001 27.7C48.5838 27.7 49.3001 26.9837 49.3001 26.1C49.3001 25.2163 48.5838 24.5 47.7001 24.5C46.8164 24.5 46.1001 25.2163 46.1001 26.1C46.1001 26.9837 46.8164 27.7 47.7001 27.7Z"
                    fill="#37307D"
                  />
                  <path
                    d="M13.4999 39.4C14.3836 39.4 15.0999 38.6836 15.0999 37.7999C15.0999 36.9163 14.3836 36.2 13.4999 36.2C12.6162 36.2 11.8999 36.9163 11.8999 37.7999C11.8999 38.6836 12.6162 39.4 13.4999 39.4Z"
                    fill="#37307D"
                  />
                  <path
                    d="M52.3001 37.2999H48.8001C48.1001 37.2999 47.5001 36.7999 47.4001 36.0999L47.1001 32.8999H54.0001L53.7001 36.0999C53.6001 36.7999 53.0001 37.2999 52.3001 37.2999Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M44.6998 32.9L41.5998 27.7H39.2998"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M50.6001 18.7L46.1001 12C45.7001 11.4 45.0001 11 44.3001 11H32.4001C31.7001 11 31.0001 11.4 30.6001 12L26.4001 17.9C26.3001 18.1 26.4001 18.4 26.6001 18.4H48.5001C51.5001 18.4 54.0001 20.8 54.0001 23.9V33H42.6001"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="txt">Самый большой собственный автопарк</div>
            </div>
            <div class="card">
              <div class="img">
                <svg
                  width="61"
                  height="60"
                  viewBox="0 0 61 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M42.36 32.8L40.4 34.89H21.29C21.29 34.89 25.02 30.71 32.85 30.71C37.03 30.71 40.14 31.82 42.36 32.8Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M49.5 34.97V40.88L46.02 42.88H45.57C45.66 42.62 45.75 42.27 45.75 41.92C45.75 40.18 44.23 38.7 42.45 38.7C40.67 38.7 39.15 40.18 39.15 41.92C39.15 42.27 39.24 42.62 39.33 42.88H20.6C20.69 42.62 20.78 42.27 20.78 41.92C20.78 40.18 19.35 38.7 17.48 38.7C15.7 38.7 14.18 40.18 14.18 41.92C14.18 42.27 14.27 42.62 14.36 42.88H11.5V37.4C11.5 37.4 15.25 34.88 18.28 34.88H40.4L42.36 32.79C44.23 33.66 45.3 34.44 45.3 34.44L49.5 34.97Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.5 37.8799H27.5"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.9399 41.8999C20.9399 42.2599 20.8499 42.6099 20.7599 42.8799C20.3199 44.2099 19.0699 45.0999 17.6499 45.0999C16.2299 45.0999 14.9799 44.1199 14.5399 42.8799C14.4499 42.5199 14.3599 42.2599 14.3599 41.8999C14.3599 40.1199 15.7799 38.6099 17.6499 38.6099C19.4199 38.6099 20.9399 40.1199 20.9399 41.8999Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M45.7402 41.8999C45.7402 42.2599 45.6502 42.6099 45.5602 42.8799C45.1202 44.2099 43.8702 45.0999 42.4502 45.0999C41.0302 45.0999 39.7802 44.1199 39.3402 42.8799C39.2502 42.5199 39.1602 42.2599 39.1602 41.8999C39.1602 40.1199 40.5802 38.6099 42.4502 38.6099C44.3202 38.6099 45.7402 40.1199 45.7402 41.8999Z"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M43.5199 21.7401L40.4599 14.8401L37.3999 21.7401"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M35.3301 21.74H45.5901"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5499 25.7001L19.4799 18.8L16.4199 25.7001"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.3501 25.7H24.6201"
                    stroke="#37307D"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="txt">Экзамены ГИБДД на нашем автодроме</div>
            </div>
          </div>
        </div>
        <div class="right">
          <form @submit="sendForm">
            <div class="title">
              Начни обучение прямо сейчас и<span> получи скидку 5%!</span>
            </div>
            <div class="fields">
              <input type="text" v-model="name" name="name" placeholder="Имя" />
              <input type="text" v-model="phone" name="phone" placeholder="Телефон" />
              <div class="input-check">
                <input type="checkbox" v-model="check" id="check" />
                <label for="check"> Согласен на обработку информации</label>
              </div>
              <button type="submit" class="btn btn-yellow">
                Отправить заявку
                <div class="svg">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 10.7771H17.5"
                      stroke="#0C0738"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 3.7771L17.5 10.7771L10.5 17.7771"
                      stroke="#0C0738"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "./Header.vue";
import Navigation from "./Navigation.vue";
import axios from "axios";

export default {
  name: "Main",
  components: {
    Header,
    Navigation,
  },
  data:()=>({
    name:null,
    phone:null,
    check:null,
  }),
  methods:{
    sendForm(e){
      e.preventDefault();
      if(!this.name){
        this.$toast.error("Введите ваше имя");
        return false;
      }
      if(!this.phone){
        this.$toast.error("Введите телефон");
        return false;
      }
      if(!this.phone.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/)){
        this.$toast.error("Проверьте корректность ввода номера телефона");
        return false;
      }
      if(!this.check){
        this.$toast.error("Укажите согласие на обработку информации");
        return false;
      }

      axios.post(`https://souzavtoshkol.ru/api/api.php`,
        {
          name: this.name,
          phone: this.phone,
          utm_source: localStorage.utm_source || null, 
          utm_campaign: localStorage.utm_campaign || null, 
          utm_content: localStorage.utm_content || null,  
          utm_medium: localStorage.utm_medium || null, 
          utm_term: localStorage.utm_term || null, 
          data: '<p><b>Место отправки:</b> Главная форма</p>'+
                '<p><b>Заголовок формы:</b> Начни обучение прямо сейчас и получи скидку 5%!</p>'
        }
      )
      .then(response => {
        if(response.data.status === 200){
          this.$toast.success("Спасибо, с вами свяжется наш менеджер!");
          this.$metrika.reachGoal('zaevka')
          this.name = null;
          this.phone = null;
          this.check = null;
        }else{
          if(response.data.status === 400){
            this.$toast.error(response.data.text);
          }else{
            this.$toast.error('Ошибка сервера, попробуйте позже');
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

#main {
  background: linear-gradient(180deg, #0C0738 0%, rgba(12, 7, 56, 0) 100%), url("../assets/img/main-bg-new.jpg") no-repeat bottom center;
  background-size: cover;
  width: 100%;
  // max-height: 575px;
  height: 100%;
  // z-index: -1;
  margin-bottom: 100px;
}
h1 {
  margin: 0px;
  
}
.main {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.left {
  position: relative;
  .title {
    font-family: "Futura DemiC";
    font-weight: 300;
    font-size: 30px;
    line-height: 1.2;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    color: #f9f9f9;
    padding: 110px 0 180px 0;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 53px;
      line-height: 112.38%;
      text-transform: uppercase;
      color: #ffe03a;
    }
  }
  .cards {
    display: flex;
    position: absolute;
    bottom: -75px;
    left: 0px;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 10px;
      width: 165px;
      height: 175px;
      background: #f9f9f9;
      box-shadow: 0px 10px 30px rgba(55, 48, 125, 0.15);
      border-radius: 10px;
      margin-right: 15px;
      box-sizing: border-box;
      .img {
        width: 60px;
        height: 60px;
      }
      .txt {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #151515;
        margin-top: 10px;
      }
    }
    .card:last-child {
      margin-right: 0;
    }
  }
}
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 20px;
  right: 0px;
  padding: 50px 25px;
  width: 365px;
  max-height: 451px;

  background: linear-gradient(180deg, rgba(12, 7, 56, 0.34) 0%, #0c0738 81.15%);
  box-shadow: 0px 10px 30px rgba(55, 48, 125, 0.35);
  border-radius: 10px;
  box-sizing: border-box;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .title {
      width: 234px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: #f9f9f9;
      margin-bottom: 50px;
      span {
        color: #ffe03a;
      }
    }
    .fields {
      input {
        margin-bottom: 20px;
      }
      .input-check {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f9f9f9;
        color: #f9f9f9;
        margin-bottom: 20px;
        position: relative;
        label {
          position: relative;
          padding-left: 30px;
        }
        label::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 0px;
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z' stroke='%23f9f9f9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
        #check {
          display: none;
        }
        #check:checked ~ label::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 9.16683L10 11.6668L18.3333 3.3335' stroke='%23f9f9f9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.5 10V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333' stroke='%23f9f9f9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  #main {
    margin-bottom: 0px;
    padding-bottom: 80px;
    padding-top:40px;
    background: linear-gradient(180deg, #0C0738 0%, rgba(12, 7, 56, 0) 68.75%, #0C0738 100%) no-repeat, url("../assets/img/main-bg-new.jpg") no-repeat;
    background-color: #0C0738;
    background-position-x: center, center;
    background-position-y: top, -320px;
    background-size: 100% 400px, cover;
  }
  .main {
    flex-direction: column;
  }
  .left {
    .title {
      padding: 87px 0 83px 0;
    }
    .cards {
      justify-content: space-between;
      width: 100%;
      position: relative;
      bottom: 0px;
      .card {
        flex-direction: row;
        width: 191px;
        height: 94px;
        .img {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
        }
        .txt {
          font-size: 14px;
          line-height: 16px;
          text-align: start;
          margin-left: 8px;
          margin-top: 0;
          max-width: 96px;
          width: 100%;
        }
      }
    }
  }
  .right {
    // background: #0c0738;
    position: relative;
    top: 0px;
    background: none;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
    padding: 40px 0 0;
    form {
      max-width: 100%;
      width: 100%;
      .title {
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
      }
      .fields {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        width: 100%;
        flex-wrap: wrap;
        position: relative;
        input {
          margin-right: 20px;
          margin-bottom: 0;
          height: 48px;
          // max-width: 430px;
          // width: 100%;
        }
        input:first-child {
          max-width: unset;
          width: 35%;
        }
        input:nth-child(2) {
          max-width: unset;
          width: 22%;
        }
        .btn {
          height: 48px;
          max-width: unset;
          width: 35%;
        }
        .input-check {
          order: 2;
          // position: absolute;
          // bottom: -60px;
          // left: 330px;
          margin: 0 auto;
          margin-top: 30px;
          label::before {
          }
        }
      }
    }
  }
  #navigation {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  #main{
    //background: linear-gradient(180deg, #0C0738 0%, rgba(12, 7, 56, 0) 68.75%, #0C0738 100%) no-repeat, url("../assets/img/header-bg.jpg") no-repeat;
    background-position-x: center, center;
    background-position-y: top, -100px;
    background-size: 100% 315px, 918px 415px;
  }
  .wrap {
    padding: 0 20px;
  }
  .left {
    .title {
      font-size: 20px;
      line-height: 112%;
      padding: 45px 0 150px 0;
      text-align: center;
      span {
        font-size: 29px;
        margin-bottom: 10px;
      }
    }
    .cards {
      flex-wrap: wrap;
      .card {
        max-width: 100%;
        width: 47%;
        height: unset;
        margin-right: 0px;
        margin-bottom: 15px;
        padding: 10px 18px;
        .txt {
          max-width: 249px;
        }
      }
      .card:last-child {
      }
    }
  }
  .right {
    form {
      .title {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 570px) {
  .left {
    .title {
      span {
      }
    }
    .cards {
      .card {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
        padding: 10px 18px;
        .txt {
          max-width: 90%;
        }
      }
    }
    .card:last-child {
    }
  }
  .right {
    form {
      .fields {
        flex-wrap: wrap;
        input:first-child, input:nth-child(2), .btn,
        .input-check {
          max-width: 100%;
          width: 100%;
          margin-right:0;
          margin-bottom:15px;
        }
      }
    }
  }
}
</style>