import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import VModal from 'vue-js-modal';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
var vueSmoothScroll = require('vue-smoothscroll');

import VueYandexMetrika from 'vue-yandex-metrika';


const options = {
  timeout: 4000,
  transition: "Vue-Toastification__fade",
  hideProgressBar: true,
};
 
Vue.use(VueRouter);
Vue.use(Toast, options);
Vue.use(vueSmoothScroll);
Vue.use(VModal)

Vue.config.productionTip = false
const routes = []
const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

Vue.use(VueYandexMetrika, {
  id: 70892215,
  router: router,
  env: process.env.NODE_ENV,
  options:{clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true}
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
