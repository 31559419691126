<template>
  <section id="popup">
    <div class="wrap">
      <div class="popup">
        <div class="left">
          <div class="img">
            <img src="@/assets/img/about-img.jpg" alt="" />
          </div>
        </div>
        <div class="close" @click.prevent="closeModal">
          <div class="svg">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 11L11 23"
                stroke="#F9F9F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 11L23 23"
                stroke="#F9F9F9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 5V0H0V5H5ZM29 5H34V0H29V5ZM29 29V34H34V29H29ZM5 29H0V34H5V29ZM5 10H29V0H5V10ZM24 5V29H34V5H24ZM29 24H5V34H29V24ZM10 29V5H0V29H10Z"
                fill="#0C0738"
              />
            </svg>
          </div>
        </div>
        <div class="right" :class="[selector ? 'has-selector' : '']">
          <form @submit="sendForm">
            <div class="title" v-if="!text">
              Начни обучение прямо сейчас и <span> получи скидку 5%! </span>
            </div>
            <div class="title" v-else>{{ text }}</div>
            <div class="fields">
              <div class="selector" v-if="selector">
                <select v-model="service" name="service">
                  <option value="" selected disabled>Выберите услугу</option>
                  <option value="Направление на медкомиссию">
                    Направление на медкомиссию
                  </option>
                  <option value="Индивидуальное вождение">
                    Индивидуальное вождение
                  </option>
                  <option value="Предоставление ТС на экзамен ГИБДД">
                    Предоставление ТС на экзамен ГИБДД
                  </option>
                  <option value="Замена водительского удостоверения">
                    Замена водительского удостоверения
                  </option>
                  <option value="Направление на техосмотр">
                    Направление на техосмотр
                  </option>
                  <option value="ОСАГО">ОСАГО</option>
                </select>
              </div>
              <input type="text" v-model="name" placeholder="Имя" />
              <input type="text" v-model="phone" placeholder="Телефон" />
              <div class="input-check">
                <input type="checkbox" v-model="check" id="check-popup" />
                <label for="check-popup">
                  Согласен на обработку информации</label
                >
              </div>
              <button class="btn btn-yellow">
                Отправить заявку
                <div class="svg">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 10.7771H17.5"
                      stroke="#0C0738"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 3.7771L17.5 10.7771L10.5 17.7771"
                      stroke="#0C0738"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  name: "Header",
  data:()=>({
    name: null,
    phone: null,
    check: null,
    service: "",
  }),
  props: {
    text: {
      type: String,
    },
    selector: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide("popup");
    },
    sendForm(e){
      e.preventDefault();
      if(!this.name){
        this.$toast.error("Введите ваше имя");
        return false;
      }
      if(!this.phone){
        this.$toast.error("Введите телефон");
        return false;
      }
      if(!this.phone.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/)){
        this.$toast.error("Проверьте корректность ввода номера телефона");
        return false;
      }
      if(!this.check){
        this.$toast.error("Укажите согласие на обработку информации");
        return false;
      }

      let modalData = '<p><b>Место отправки:</b> Модальное окно</p>';
      if(this.text){
        modalData += '<p><b>Заголовок:</b> '+ this.text+'</p>';
      }else{
        modalData += '<p><b>Заголовок:</b> Начни обучение прямо сейчас и получи скидку 5%! </p>';
      }

      if(this.message != ""){
        modalData += this.message;
      }

      if(this.selector === true){
        if(this.service === ""){
          this.$toast.error("Выберите услугу");
          return false;
        }else{
          modalData += '<p><b>Услуга: </b>'+ this.service +'</p>';
        }
      }

      
      axios.post(`https://souzavtoshkol.ru/api/api.php`,
        {
          name: this.name,
          phone: this.phone,
          utm_source: localStorage.utm_source || null, 
          utm_campaign: localStorage.utm_campaign || null, 
          utm_content: localStorage.utm_content || null,  
          utm_medium: localStorage.utm_medium || null, 
          utm_term: localStorage.utm_term || null, 
          data: modalData
        }
      )
      .then(response => {
        if(response.data.status === 200){
          this.$toast.success("Спасибо, с вами свяжется наш менеджер!");
          this.$metrika.reachGoal('zaevka');
          this.name = null;
          this.phone = null;
          this.check = null;
          this.$modal.hide("popup");
        }else{
          if(response.data.status === 400){
            this.$toast.error(response.data.text);
          }else{
            this.$toast.error('Ошибка сервера, попробуйте позже');
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#popup {
  width: 730px;
  height: 520px;
  background: #0c0738;
  border: 10px solid #0c0738;
  box-shadow: 0px 10px 30px rgba(55, 48, 125, 0.15);
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
}
.popup {
  display: flex;
  position: relative;
}
.left {
  margin-right: 10px;
  .img {
    object-fit: cover;
    width: 365px;
    height: 520px;
  }
}
.close {
  position: absolute;
  z-index: 3;
  top: 0px;
  right: 0px;
  cursor: pointer;
  .svg {
    width: 24px;
    height: 24px;
  }
}
.right {
  z-index: 1;
  padding: 56px 20px;
}
.right.has-selector{
  padding:20px;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .title {
    width: 234px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #f9f9f9;
    margin-bottom: 50px;
    span {
      color: #ffe03a;
    }
  }
  .fields {
    .selector {
      width: 100%;
      & > select {
        appearance: none;
        -webkit-appearance: none;
        width: 100%;
        margin-bottom: 20px;
        background: #f7f7f7;
        border: 1.5px solid #dcdaed;
        padding: 13px 15px;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
    input {
      margin-bottom: 20px;
      appearance: none;
        -webkit-appearance: none;
    }
    .input-check {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f9f9f9;
      color: #f9f9f9;
      margin-top: 30px;
      margin-bottom: 30px;
      position: relative;
      label {
        position: relative;
        padding-left: 30px;
      }
      label::before {
        content: "";
        position: absolute;
        top: -2px;
        left: 0px;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z' stroke='%23f9f9f9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
      #check-popup {
        display: none;
      }
      #check-popup:checked ~ label::before {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 9.16683L10 11.6668L18.3333 3.3335' stroke='%23f9f9f9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.5 10V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333' stroke='%23f9f9f9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
.right.has-selector{
  form{
    .title{
      margin-bottom:20px;
    }
    .fields{
      .input-check{
        margin-top:10px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .wrap {
    padding: inherit;
  }
}
@media (max-width: 767px) {
  #popup {
    width: 100%;
    height: 100%;
  }
  .popup {
    justify-content: center;
  }
  .left {
    display: none;
  }
  form {
    .title {
      width: 100%;
      margin-bottom: 20px;
    }
    .fields {
      input {
        max-width: 100%;
      }
      .input-check {
        margin: 0px 0 20px;
      }
      .btn {
        max-width: 100%;
      }
    }
  }
}
</style>