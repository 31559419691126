<template>
  <div class="accordion" id="accordion">
    <div
      class="accordion-item"
      :class="[active === key ? 'active' : '']"
      v-for="(el, key) in services"
      :key="key"
    >
      <div class="head" @click="accordion(key)">
        <span>{{ el.title }}</span>
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M1 0.56842L6 5.56842L11 0.56842"
            stroke="#37307D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="body">
        {{ el.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: -1,
    services: [
      {
        title: "Направление на медкомиссию",
        text:
          "Прохождение медицинской комиссии на наличие противопоказаний к управлению транспортными средствами.",
      },
      {
        title: "Индивидуальное вождение",
        text:
          "Обучение предназначено для лиц окончивших недобросовестную автошколу или желающих восстановить навыки после длительного перерыва.",
      },
      {
        title: "Предоставление ТС на экзамен ГИБДД",
        text:
          "Предоставление транспортного средства для сдачи экзамена ГИБДД. Для выпускников Союза Автошкол бесплатно.",
      },
      {
        title: "Замена водительского удостоверения",
        text:
          "Подготовка полного комплекта документов для замены водительского удостоверения. Медсправка, Заявление, Госпошлина, Ксерокопии необходимых документов и консультация.",
      },
      {
        title: "Направление на техосмотр",
        text: "Получение диагностической карты для транспортных средств.",
      },
      {
        title: "ОСАГО",
        text:
          "Оформление полиса обязательного страхования автогражданской ответственности.",
      },
    ],
  }),
  methods: {
    accordion(key) {
      if (this.active === key) this.active = -1;
      else this.active = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  width: 100%;
  height: 100%;
  font-family: "Roboto";

  .accordion-item {
    position: relative;
    margin-bottom: 10px;
    & > .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      padding-left: 15px;
      padding-right: 15px;
      & > span {
        color: #37307d;
        font-size: 14px;
        line-height: 16px;
      }
      & > svg {
        width: 12px;
        height: 7px;
      }
    }
    & > .body {
      font-size: 0;
      opacity: 0.5;
      color: #37307d;
      overflow: hidden;
      transition: all 0.3s ease;
      margin-top: 5px;
      padding: 0 15px;
    }
    &.active {
      & > .head {
      }
      & > .body {
        height: auto;
        font-size: 14px;
        line-height: 16px;

        /* purple */
      }
    }
  }
}
</style>