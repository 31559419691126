<template>
  <div class="video">
    <video width="100%" height="100%" controls autoplay>
      <source src="/static/img/video.mp4" type="video/mp4" />
    </video>
    <div class="close" @click="closeVideo">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 11L11 23"
          stroke="#F9F9F9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 11L23 23"
          stroke="#F9F9F9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5V0H0V5H5ZM29 5H34V0H29V5ZM29 29V34H34V29H29ZM5 29H0V34H5V29ZM5 10H29V0H5V10ZM24 5V29H34V5H24ZM29 24H5V34H29V24ZM10 29V5H0V29H10Z"
          fill="#0C0738"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        closeVideo(){
            this.$modal.hide('video');
        }
    }
};
</script>

<style lang="scss" scoped>
.video {
  max-width: 768px;
  max-height: 432px;
  width: 100%;
  height: 100%;
  border: 0;
  position: relative;
  .close {
      background:#0C0738;
      position: absolute;
      top:0;
      right:0;
      width:40px;
      height:40px;
      z-index: 5;
      cursor: pointer;
  }

  video {
    border: 0;
    margin: 0;
  }
}
</style>