<template>
  <section id="reviews">
    <div class="wrap">
      <div class="reviews-container">
        <!-- Верх блока -->
        <div class="title-container">
          <div class="title">Довольные ученики о нас</div>
          <div class="link">
            Открыть все отзывы во
            <a target="_blank" href="https://vk.com/wall-35099408">Вконтакте</a>
            <div class="svg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8335 14.1667L14.1668 5.83337"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.8335 5.83337H14.1668V14.1667"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="reviews">
          <div class="top">
            <div class="left">
              <div class="rev">
                <div class="top-rev">
                  <div class="quotes">
                    <svg
                      width="61"
                      height="56"
                      viewBox="0 0 61 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.1">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M60.2276 0.0755615H60.2279V16.79H60.2276C54.0705 16.79 49.0791 21.7787 49.079 27.9326H60.2276V39.0756H60.2279V55.7894H60.2276L60.2276 55.7895H32.3562V27.9326C32.3562 27.9325 32.3562 27.9325 32.3562 27.9325C32.3562 12.5475 44.8347 0.0755615 60.2276 0.0755615Z"
                          fill="#37307D"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M28.0682 0H28.0686V16.7141H28.0683C21.9111 16.7141 16.9197 21.7029 16.9197 27.8569C16.9197 27.8569 16.9197 27.8569 16.9197 27.8569H28.0682V38.9996C28.0682 38.9996 28.0682 38.9996 28.0683 38.9996H28.0686V55.7138H28.0682C28.0613 55.7138 28.0543 55.7138 28.0474 55.7138H0.196777V27.8569C0.196777 12.472 12.6752 0 28.0682 0Z"
                          fill="#37307D"
                        />
                      </g>
                    </svg>
                  </div>
                  <div class="name">{{ reviews[currentReviewId].name }}</div>
                </div>
                <div class="txt">
                  {{ reviews[currentReviewId].text }}
                </div>
              </div>
              <div class="pagination">
                <div
                  @click.prevent="reviewsPrev()"
                  class="back"
                  :class="prevIsActive() ? '' : 'deactive'"
                >
                  <div class="svg">
                    <svg
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.1967 36.6667C29.4014 36.6667 36.8634 29.2048 36.8634 20C36.8634 10.7953 29.4014 3.33337 20.1967 3.33337C10.992 3.33337 3.53003 10.7953 3.53003 20C3.53003 29.2048 10.992 36.6667 20.1967 36.6667Z"
                        :stroke="prevIsActive() ? '#37307D' : 'black'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.1967 13.3334L13.53 20L20.1967 26.6667"
                        :stroke="prevIsActive() ? '#37307D' : 'black'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26.8634 20H13.53"
                        :stroke="prevIsActive() ? '#37307D' : 'black'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  Предыдущий
                </div>
                <div class="points">
                  <span
                    v-for="(element, key) in reviews"
                    :key="key"
                    :class="[key === currentReviewId ? 'active' : '']"
                  ></span>
                </div>
                <div
                  @click.prevent="reviewsNext()"
                  class="next"
                  :class="nextIsActive() ? '' : 'deactive'"
                >
                  Следующий
                  <div class="svg">
                    <svg
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.1969 36.6667C29.4017 36.6667 36.8636 29.2048 36.8636 20C36.8636 10.7953 29.4017 3.33337 20.1969 3.33337C10.9922 3.33337 3.53027 10.7953 3.53027 20C3.53027 29.2048 10.9922 36.6667 20.1969 36.6667Z"
                        :stroke="nextIsActive() ? '#37307D' : 'black'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.1968 26.6667L26.8634 20L20.1968 13.3334"
                        :stroke="nextIsActive() ? '#37307D' : 'black'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5303 20H26.8636"
                        :stroke="nextIsActive() ? '#37307D' : 'black'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="img">
                <img :src="reviews[currentReviewId].img1" alt="" />
              </div>
              <div class="img">
                <img :src="reviews[currentReviewId].img2" alt="" />
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="btn btn-yellow" @click="showModal">
              Тоже хочу права
              <div class="svg">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.0005 9.58336C18.0034 10.6832 17.7464 11.7683 17.2505 12.75C16.6625 13.9265 15.7586 14.916 14.64 15.6078C13.5214 16.2995 12.2324 16.6662 10.9172 16.6667C9.81726 16.6696 8.73225 16.4126 7.75049 15.9167L3.00049 17.5L4.58382 12.75C4.08793 11.7683 3.83095 10.6832 3.83382 9.58336C3.83433 8.26815 4.201 6.97907 4.89275 5.86048C5.58451 4.7419 6.57403 3.838 7.75049 3.25002C8.73225 2.75413 9.81726 2.49716 10.9172 2.50002H11.3338C13.0708 2.59585 14.7114 3.32899 15.9414 4.55907C17.1715 5.78915 17.9047 7.42973 18.0005 9.16669V9.58336Z"
                    stroke="#0C0738"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Popup from "./Popup.vue";
export default {
  name: "Reviews",
  props: {
    msg: String,
  },
  data: () => ({
    currentReviewId: 0,
    reviews: [
      {
        name: "Лилия, 18 лет",
        text:
          "Классная школа! Недавно сдала экзамен, правда со второго раза, так как пришлось пропустить пару занятий из-за работы! У меня такая работа. что выходные не попадали на время занятий, я уже отчаялась нормально закончить обучение, но в школе вошли в мое положение, и мы подобрали удобный для меня график занятий.",
        img1: "/static/img/reviews/rev-img-1-1.jpg",
        img2: "/static/img/reviews/rev-img-1-2.jpg",
      },
      {
        name: "Дарья, 19 лет",
        text:
          "Вот и получила свое долгожданное водительское удостоверение!🎉🚘 Хочу выразить благодарность преподавателю теории📒, Владимиру Сергеевичу, на каждое занятие шла с удовольствием.🙃 Преплдаватель всегда объяснял доходчиво, всегда с примерами из жизни и, конечно же, куда без шуток!🤠 Отдельную благодарность хочу выразить моему инструктору, Сергею Сергеевичу!🔥🔥🔥Замечательный человек, научил вождению с нуля, помню свое первое занятие, когда боялась тронуться, и вот, я уже получаю ВУ, и езжу сама. 🚗🎉Шла на урок вождения каждый раз, как на праздник! Всегда выслушает, поможет и поддержит!😊 Было очень обидно, когда занятия теории и практики закончились, это время пролетело очень быстро. И в целом, спасибо Союзавтошкол за таких замечательных работников, которые любят свое дело! 💛💙Очень рада, что выбрала Союзавтошкол, ведь эта автошкола реально учит! 🤓",
        img1: "/static/img/reviews/rev-img-3-2.jpg",
        img2: "/static/img/reviews/rev-img-3-1.jpg",
      },
      {
        name: "Вероника, 18 лет",
        text:
          "Дааа,вот оно мое долгожданное водительское удостоверение 🚘  Я - водитель! Я не верю просто😭 Автошкола 'С.А.' Союз Автошкол г.Ростов-на-Дону , вы лучшие 💙💛 Это были самые незабываемые 3 месяца обучения🌟 Хочу сказать огромное спасибо преподавателю по теории Владимиру Сергеевичу, за то что интересно и доступно объясняли материал,всегда помогали и отвечали на интересующие вопросы. Вы лучший!🌟 Огромная благодарность моему инструктору по вождению Сергею Сергеевичу. Помню,как было страшно и волнительно начинать занятия по вождению. Но после первого занятия поняла,что я попала к профессионалу своего дела! Сергей Сергеевич, спасибо за Ваше железное терпение (иногда я не понимала,откуда столько терпения у человека 😂",
        img1: "/static/img/reviews/rev-img-4-2.jpg",
        img2: "/static/img/reviews/rev-img-4-1.jpg",
      },
      {
        name: "Александр",
        text:
          "Благодарю Автошколу С.А за полученные теоретические и практические знания . Особенно хочется поблагодарить инструктора по вождению Любовь Александровну , которая меня научила всему , вплоть до заправки автомобиля . Каждое занятие ждал с нетерпением , я могу уверено двигаться за рулем , в плотном городском потоке . Так же , буквально на 5 занятии мне посчастливилось пересесть на новый Рено Логан и продолжать мое обучение в полном комфорте . Очень доволен Автошколой , а особенно практическими занятиями . Главное выбирать Автошколу где реально учат !",
        img1: "/static/img/reviews/rev-img-5-1.jpg",
        img2: "/static/img/reviews/rev-img-5-2.jpg",
      },
      {
        name: "Екатерина",
        text:
          "Меня зовут Екатерина и я живу в Ростовской области, а автошколу выбрала в Ростове-на-Дону и именно Союз Автошкол, и не разу об этом не пожалела. Благодаря обучению в плотном городском потоке я без проблем сдала экзамены и получила водительское удостоверение. Огромное спасибо хочу сказать моему инструктору Любови Александровне, которая научила меня всему от вождения до заправки и мойки автомобиля. Выбирайте автошколу не ту, которая рядом с домом, а ту, которая реально учит!",
        img1: "/static/img/reviews/rev-img-6-1.jpg",
        img2: "/static/img/reviews/rev-img-6-2.jpg",
      },
    ],
  }),
  methods: {
    showModal() {
      this.$modal.show(
        Popup,
        {},
        {
          name: "popup",
          adaptive: true,
          minWidth: 300,
          maxWidth: 730,
          width: "100%",
          height: "auto",
        },
        {}
      );
    },
    prevIsActive() {
      return this.currentReviewId > 0 ? true : false;
    },
    nextIsActive() {
      return this.currentReviewId < this.reviews.length - 1 ? true : false;
    },
    reviewsNext() {
      let countReviews = this.reviews.length;
      let current = this.currentReviewId;
      if (current < countReviews - 1) {
        this.currentReviewId += 1;
      } else {
      }
    },
    reviewsPrev() {
      if (this.currentReviewId > 0) this.currentReviewId -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
#reviews {
  padding: 50px 0 71px;
}
.reviews-container {
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 50px;
    color: #37307d;
  }
  .link {
    display: flex;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    user-select: none;
    a {
      color: #2787f5;
      margin-left: 5px;
    }
    .svg {
      width: 20px;
      height: 20px;
    }
  }
}
.reviews {
  margin-top: 73px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      max-width: 565px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
      .rev {
        .top-rev {
          // margin-bottom: 100px;
          .quotes {
            width: 60px;
            height: 55px;
            margin-bottom: 29px;
          }
          .name {
            margin-bottom: 25px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: #37307d;
          }
        }
        .txt {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: #151515;
        }
      }
      .pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 435px;
        width: 100%;
        margin-top: 40px;
        .back,
        .next {
          &.deactive {
            opacity: 0.4;
          }
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          user-select: none;
          .svg {
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
        }
        .back {
          .svg {
            margin-right: 10px;
          }
        }
        .next {
          .svg {
            margin-left: 10px;
          }
        }
        .points {
          display: flex;
          span {
            width: 5px;
            height: 5px;
            background: #37307d;
            opacity: 0.2;
            border-radius: 50%;
            margin-right: 8px;
          }
          span:last-child {
            margin-right: 0px;
          }
          .active {
            background: #37307d;
            opacity: 1;
          }
        }
        .next {
        }
      }
    }
    .right {
      max-width: 565px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .img {
        max-width: 265px;
        width: 100%;
        max-height: 368px;
        height: 100%;
        user-select: none;
        object-fit: cover;
      }
      .img:first-child {
        margin-right: 20px;
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .btn {
    }
  }
}
@media screen and (max-width: 1200px) {
  .title-container {
    .title {
      font-size: 25px;
      line-height: 33px;
      color: #151515;
    }
    .link {
      font-size: 14px;
      line-height: 27px;
    }
  }
  .reviews {
    .top {
      .left {
        .rev {
          .top-rev {
            display: flex;
            align-items: center;
            .quotes {
              width: 32px;
              height: 30px;
            }
            .name {
              margin-left: 10px;
            }
          }
          .txt {
            font-size: 14px;
            line-height: 16px;
          }
        }
        .pagination {
          margin-top: 33px;
          .back,
          .next {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 838px) {
  .wrap {
    padding: 0 30px;
  }
}
@media screen and (max-width: 690px) {
  .title-container {
    flex-direction: column;
    align-items: flex-start;
    .title {
      margin-bottom: 7px;
    }
  }
  .reviews {
    margin-top: 20px;
    .top {
      flex-direction: column;
      .left {
        margin-top: 20px;
        margin-right: 0;
        order: 0;
        max-width: 100%;
        width: 100%;
        .pagination {
          width: 100%;
          max-width: 100%;
        }
      }
      .right {
        order: -1;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 510px) {
  .wrap {
    padding: 0 20px;
  }
  .reviews {
    .top {
      .left {
        .pagination {
          .back,
          .next {
            .svg {
              width: 25px;
              height: 25px;
            }
          }
          .back {
            .svg {
              margin-right: 5px;
            }
          }
          .next {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  // .wrap {
  //   padding: 0 10px;
  // }
}
</style>