<template>
  <section id="map">
    <div class="wrap">
      <h2 class="map-title">Нас легко найти</h2>

      <div class="map-container">
        <div class="map-inner">
          <yandex-map
            @map-was-initialized="mapLoaded"
            :coords="currentCoord"
            style="width: 100%; height: 100%"
            :zoom="zoom"
            :settings="settings"
          >
            <ymap-marker
              v-for="mark in getMarks()"
              :key="mark.id"
              marker-type="placemark"
              :marker-id="mark.id"
              :coords="mark.coords"
              hint-content=""
              cluster-name="CA"
              :balloon="{
                header: mark.type,
                body: mark.address,
                footer: `<a style='font-size:14px;' href='tel:+78632210707'>+7 863 221 07 07</a><br>График работы: <br><div style='line-height:1.15; color:#000; font-size:13px;' >${mark.time}</div>`,
              }"
              :icon="{
                content: `<div class='ballon-icon'></div>`,
                ...markerIcon,
              }"
              @click="markClick(mark, $event)"
            ></ymap-marker>
            <ymap-marker
              marker-type="placemark"
              marker-id="user"
              :coords="userMark.coords"
              hint-content=""
              :icon="{
                content: '',
                layout: 'default#imageWithContent',
                imageHref: '/static/img/svg/location.svg',
                imageSize: [30, 30],
                imageOffset: [-15, -25],
              }"
              :balloon="{ header: 'Вы здесь', body: '', footer: '' }"
            ></ymap-marker>
          </yandex-map>
        </div>
        <div class="map-bar">
          <div class="map-links">
            <div class="title" @click="toggleBar">Наши адреса</div>
            <div class="info">
              <div class="info-item">
                <span>Единый номер: </span
                ><a href="tel:88632210707">+7 863 221 07 07</a>
              </div>
              <!-- <div class="info-item">
                <span>Адрес автодрома: </span>ул. Курская, 4Д
              </div> -->
            </div>
            <vue-custom-scrollbar
              tagname="div"
              ref="scrollBarMap"
              id="scrollBar"
              class="address"
              :class="[mobileBarActive ? 'active' : '']"
              :settings="scrollSet"
            >
              <div
                v-for="(el, g) in newPlacemarks"
                :key="g"
                :id="'area' + g"
                class="group"
              >
                <div class="area">{{ el.area }}</div>
                <div
                  class="item"
                  @click="selectMarker(e)"
                  :class="[e.id === selectMark ? 'active' : '']"
                  v-for="e in el.marks"
                  :key="e.id"
                >
                  {{ e.address }}
                </div>
              </div>
              <div class="moz-fix"></div>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  components: { yandexMap, ymapMarker, vueCustomScrollbar },
  data: () => ({
    selectMark: -1,
    currentCoord: [47.23194, 39.75405],
    zoom: 14,
    saMap: {},
    settings: {
      apiKey: "28f60e85-058f-4d08-9707-03d2e450a252",
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    },
    scrollSet: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: false,
    },
    markerIcon: {
      layout: "default#imageWithContent",
      imageHref: "",
      imageSize: [40, 40],
      imageOffset: [-20, -15],
      contentOffset: [0, 0],
      contentLayout: '<div style="">$[properties.iconContent]</div>',
    },

    newPlacemarks: [
      {
        area: "Адрес автодрома",
        marks: [
          {
            id: 1021,
            g: 1,
            type: "Автодром",
            time: "Круглосуточно",
            address: "ул. Курская, 4Д",
            coords: [47.243512, 39.664246],
          },
        ],
      },
      {
        area: "Центр",
        marks: [
          {
            id: 1001,
            g: 2,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "ул. Максима Горького, 11/43",
            coords: [47.222916, 39.695897],
          },
          {
            id: 1002,
            g: 2,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб-вс 10:00 - 17:00<br>Перерыв с 12:00 до 13:00`,
            address: "пр. Ворошиловский, 91",
            coords: [47.230627, 39.71485],
          },
          {
            id: 1003,
            g: 2,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб-вс - выходной<br>Перерыв с 12:00 до 13:00`,
            address: "пр. Соколова, 6",
            coords: [47.218864, 39.723542],
          },
          {
            id: 1004,
            g: 2,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "пр. Буденновский, 96",
            coords: [47.236702, 39.70039],
          },
          {
            id: 1005,
            g: 2,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "пер. Университетский, 54",
            coords: [47.22714, 39.727274],
          },
          {
            id: 1006,
            g: 2,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "ул. Максима Горького, 287",
            coords: [47.232082, 39.73916],
          },
        ],
      },
      {
        area: "РИИЖТ",
        marks: [
          {
            id: 1007,
            g: 3,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "пр. Ленина, 83",
            coords: [47.249727, 39.699478],
          },
        ],
      },
      {
        area: "Северный",
        marks: [
          {
            id: 1008,
            g: 4,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "пр. Михаила Нагибина, 35",
            coords: [47.259184, 39.717066],
          },
          {
            id: 1009,
            g: 4,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "б-р. Комарова, 26",
            coords: [47.289749, 39.707454],
          },
          {
            id: 1010,
            g: 4,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "ул. Беляева, 24",
            coords: [47.299695, 39.724068],
          },
        ],
      },
      {
        area: "Западный",
        marks: [
          {
            id: 1011,
            g: 5,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "пр. Коммунистический, 29",
            coords: [47.207521, 39.629959],
          },
          {
            id: 1012,
            g: 5,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "ул. Интернациональная, 1/105",
            coords: [47.210396, 39.67411],
          },
          {
            id: 1013,
            g: 5,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "ул. Зорге, 40А",
            coords: [47.217757, 39.633533],
          },
        ],
      },
      {
        area: "Левенцовка",
        marks: [
          {
            id: 1014,
            g: 6,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "ул. Еременко, 62",
            coords: [47.231736, 39.618421],
          },
          {
            id: 1015,
            g: 6,
            type: "Союз Автошкол",
            time: `пн-вс 10:00 - 22:00<br>Перерыв с 14:00 до 15:00`,
            address: "ТЦ Золотой Вавилон: ул. Малиновского 25",
            coords: [47.230554, 39.610472],
          },
        ],
      },
      {
        area: "Военвед",
        marks: [
          {
            id: 1016,
            g: 7,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "ул. Таганрогская, 116Г",
            coords: [47.258881, 39.649164],
          },
        ],
      },
      {
        area: "Сельмаш",
        marks: [
          {
            id: 1017,
            g: 8,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "пр. Сельмаш, 98/11",
            coords: [47.254494, 39.76569],
          },
        ],
      },
      {
        area: "Нахичевань",
        marks: [
          {
            id: 1018,
            g: 9,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 12:00 до 13:00`,
            address: "пл. Толстого, 8",
            coords: [47.23192, 39.764938],
          },
        ],
      },
      {
        area: "Александровка",
        marks: [
          {
            id: 1019,
            g: 10,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс – выходной<br>Перерыв с 13:00 до 14:00`,
            address: "пр. 40-летия Победы, 89",
            coords: [47.239115, 39.82886],
          },
        ],
      },
      {
        area: "г. Аксай",
        marks: [
          {
            id: 1020,
            g: 11,
            type: "Союз Автошкол",
            time: `пн-пт 9:00 - 19:00<br>сб 10:00 - 17:00<br>вс - выходной<br>Перерыв с 13:00 до 14:00`,
            address: "пр. Ленина, 30",
            coords: [47.268848, 39.861317],
          },
        ],
      },
    ],

    userPosition: {},
    userMark: {
      coords: [47.23194, 39.75405],
    },
    mobileBarActive: false,
  }),
  methods: {
    getMarks() {
      let a = this.newPlacemarks.map((e) => e.marks);
      let b = [];
      for (let val of Object.values(a)) {
        b.push(...val);
      }
      return b;
    },
    toggleBar() {
      this.mobileBarActive = !this.mobileBarActive;
    },
    markClick(mark) {
      this.selectMark = mark.id;
      this.currentCoord = mark.coords;
      let cont = document.getElementById("scrollBar");
      let s = 0;
      let i = 0;
      while (i < mark.g - 1) {
        s = s + document.getElementById("area" + i).offsetHeight;
        i++;
      }
      cont.scrollTop = s;
      //this.saMap.setZoom(16);
    },
    selectMarker(mark) {
      this.selectMark = mark.id;
      this.currentCoord = mark.coords;
      this.mobileBarActive = false;
      this.saMap.setZoom(16, { smooth: true, centering: true });
    },
    mapLoaded: function (e) {
      this.saMap = e;
      this.userPosition = ymaps.geolocation.get();
      this.userPosition
        .then((result) => {
          this.userMark = result;
          this.$set(this.userMark, "coords", [
            result.geoObjects.position[0],
            result.geoObjects.position[1],
          ]);
        })
        .then(() => {
          this.currentCoord = this.userMark.coords;
        });
    },
  },
};
</script>

<style lang="scss">
.ballon-icon {
  width: 40px;
  height: 40px;
  background: url("/static/img/map-icon.png") no-repeat center center;
  background-size: 40px 40px;
}
.mark-active {
  .ballon-text {
    border: 3px solid #000;
  }
}
#map {
  margin-top: -270px;
}
.map-title {
  font-family: "Roboto Slab";
  font-weight: normal;
  font-size: 38px;
  line-height: 50px;
  color: #f9f9f9;
  margin: 0;
  margin-bottom: 30px;
}
.map-container {
  position: relative;
  width: 100%;
  height: 605px;
  box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  .map-inner {
    position: absolute;
    width: calc(100% - 365px);
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
  }
  .map-bar {
    z-index: 1;
    position: absolute;
    right: 0;
    max-width: 365px;
    height: 100%;
    width: 100%;
    background: #0c0738;
    border-radius: 0 10px 10px 0;
    .map-links {
      height: 100%;
      width: 100%;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        z-index: 2;
        background: linear-gradient(
          180deg,
          rgba(12, 7, 56, 0) 0%,
          #0c0738 63.02%
        );
        border-bottom-right-radius: 10px;
      }
      & > .title {
        padding: 40px 0 30px 25px;
        font-size: 20px;
        line-height: 23px;
        color: #f9f9f9;
      }
      & > .info {
        padding: 0 0 24px 25px;
        & > .info-item {
          color: #fff;
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 19px;
          position: relative;
          &::after {
            content: "";
            width: 100px;
            position: absolute;
            bottom: -20px;
            border-bottom: 1px solid #ffe03a;
            border-radius: 50%;
            left: 0;
          }
          & > a {
            color: #fff;
          }
          & > span {
            color: rgba(249, 249, 249, 0.5);
          }
        }
      }
    }
  }
}

.address {
  width: 365px;
  height: 410px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  padding-bottom: 80px;
  .area {
    margin-left: 10px;
    font-size: 14px;
    padding: 10px 0 10px 25px;
    color: #f9f9f9;
    opacity: 0.6;
  }
  .item {
    width: 100%;
    background-color: transparent;
    color: #f9f9f9;
    transition: all 0.3s ease;
    padding: 10px 0 10px 25px;
    cursor: pointer;
    &:hover {
      color: #37307d;
      background-color: #f9f9f9;
    }
    &.active {
      color: #37307d;
      background-color: #f9f9f9;
    }
  }
}
@media (max-width: 920px) {
  .map-title {
    display: none;
  }
  .map-container {
    & > .map-inner {
      width: 100%;
      border-radius: 10px;
    }
    .map-bar {
      top: -10px;
      max-width: 100%;
      height: 100px;
      overflow: unset;
      border-radius: 10px;
      .map-links {
        & > .info {
          display: none;
        }
        &::after {
          display: none;
        }
        & > .title {
          position: relative;
          padding-right: 25px;
          &::after {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 45%;
            right: 25px;
            background: url("/static/img/svg/arrow-down.svg") no-repeat center
              center;
            background-size: 100%;
          }
        }
      }
    }
  }
  .address {
    position: absolute;
    overflow: hidden;
    border-radius: 10px;
    top: 80px;
    width: 100%;
    height: 250px;
    transform: rotateX(90deg);
    z-index: 10;
    padding-top: 20px;
    background: #0c0738;
    &.active {
      transform: rotateX(0deg);
    }
  }
}
@media (min-width:920px){
  .moz-fix{
    height: 60px;
  }
}
</style>