var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"map"}},[_c('div',{staticClass:"wrap"},[_c('h2',{staticClass:"map-title"},[_vm._v("Нас легко найти")]),_c('div',{staticClass:"map-container"},[_c('div',{staticClass:"map-inner"},[_c('yandex-map',{staticStyle:{"width":"100%","height":"100%"},attrs:{"coords":_vm.currentCoord,"zoom":_vm.zoom,"settings":_vm.settings},on:{"map-was-initialized":_vm.mapLoaded}},[_vm._l((_vm.getMarks()),function(mark){return _c('ymap-marker',{key:mark.id,attrs:{"marker-type":"placemark","marker-id":mark.id,"coords":mark.coords,"hint-content":"","cluster-name":"CA","balloon":{
              header: mark.type,
              body: mark.address,
              footer: ("<a style='font-size:14px;' href='tel:+78632210707'>+7 863 221 07 07</a><br>График работы: <br><div style='line-height:1.15; color:#000; font-size:13px;' >" + (mark.time) + "</div>"),
            },"icon":Object.assign({}, {content: "<div class='ballon-icon'></div>"},
              _vm.markerIcon)},on:{"click":function($event){return _vm.markClick(mark, $event)}}})}),_c('ymap-marker',{attrs:{"marker-type":"placemark","marker-id":"user","coords":_vm.userMark.coords,"hint-content":"","icon":{
              content: '',
              layout: 'default#imageWithContent',
              imageHref: '/static/img/svg/location.svg',
              imageSize: [30, 30],
              imageOffset: [-15, -25],
            },"balloon":{ header: 'Вы здесь', body: '', footer: '' }}})],2)],1),_c('div',{staticClass:"map-bar"},[_c('div',{staticClass:"map-links"},[_c('div',{staticClass:"title",on:{"click":_vm.toggleBar}},[_vm._v("Наши адреса")]),_vm._m(0),_c('vue-custom-scrollbar',{ref:"scrollBarMap",staticClass:"address",class:[_vm.mobileBarActive ? 'active' : ''],attrs:{"tagname":"div","id":"scrollBar","settings":_vm.scrollSet}},[_vm._l((_vm.newPlacemarks),function(el,g){return _c('div',{key:g,staticClass:"group",attrs:{"id":'area' + g}},[_c('div',{staticClass:"area"},[_vm._v(_vm._s(el.area))]),_vm._l((el.marks),function(e){return _c('div',{key:e.id,staticClass:"item",class:[e.id === _vm.selectMark ? 'active' : ''],on:{"click":function($event){return _vm.selectMarker(e)}}},[_vm._v(" "+_vm._s(e.address)+" ")])})],2)}),_c('div',{staticClass:"moz-fix"})],2)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-item"},[_c('span',[_vm._v("Единый номер: ")]),_c('a',{attrs:{"href":"tel:88632210707"}},[_vm._v("+7 863 221 07 07")])])])}]

export { render, staticRenderFns }