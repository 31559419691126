<template>
  <section id="about">
    <div class="wrap">
      <div class="about-container">
        <!-- Верх блока -->
        <div class="title">
          <!-- <div class="yellow">АВТОШКОЛА</div>
          <div class="blue">где реально учат</div> -->
          <div class="title-img">
            <img src="/static/img/about-title-trans2.png" alt="">
          </div>
        </div>
        <div class="about-img-container">
          <div class="about-img" @click="showLive">
            <div class="text">
              Прямая трансляция автодрома
              <div class="svg">
              <svg
                width="48"
                height="47"
                viewBox="0 0 48 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.0016 42.7738C34.7451 42.7738 43.4544 34.0691 43.4544 23.3312C43.4544 12.5933 34.7451 3.88855 24.0016 3.88855C13.2581 3.88855 4.54883 12.5933 4.54883 23.3312C4.54883 34.0691 13.2581 42.7738 24.0016 42.7738Z"
                  fill="#FFE03A"
                  stroke="#FFE03A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.1108 15.5542L31.7825 23.3313L20.1108 31.1083V15.5542Z"
                  fill="#FFE03A"
                  stroke="#37307D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            </div>
          </div>
        </div>
        <!-- Кнопка - оставить заявку -->
        <div class="about-bottom">
          <div class="left">
            <!-- Элементы со звездочкой -->
            <div class="items">
              <div class="item">
                <div class="star">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.1"
                      d="M10 2.5L12.4922 7.06977L17.6085 8.02786L14.0325 11.8102L14.7023 16.9721L10 14.74L5.29772 16.9721L5.96752 11.8102L2.39155 8.02786L7.50779 7.06977L10 2.5Z"
                      fill="#37307D"
                    />
                  </svg>
                </div>
                <div class="txt">
                  <div class="txt-bold">Мы не привязываем вас деньгами</div>
                  <div class="txt-small">Первоначальный взнос от 900 рублей</div>
                </div>
              </div>
              <div class="item">
                <div class="star">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.1"
                      d="M10 2.5L12.4922 7.06977L17.6085 8.02786L14.0325 11.8102L14.7023 16.9721L10 14.74L5.29772 16.9721L5.96752 11.8102L2.39155 8.02786L7.50779 7.06977L10 2.5Z"
                      fill="#37307D"
                    />
                  </svg>
                </div>
                <div class="txt">
                  <div class="txt-bold">Нет скрытых платежей</div>
                  <div class="txt-small">
                    Беспроцентная, нефиксированная рассрочка на весь срок
                    обучения
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="star">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.1"
                      d="M10 2.5L12.4922 7.06977L17.6085 8.02786L14.0325 11.8102L14.7023 16.9721L10 14.74L5.29772 16.9721L5.96752 11.8102L2.39155 8.02786L7.50779 7.06977L10 2.5Z"
                      fill="#37307D"
                    />
                  </svg>
                </div>
                <div class="txt">
                  <div class="txt-bold">Нам нечего скрывать</div>
                  <div class="txt-small">
                    Контроль качества – возможность пригласить на занятия своих родных, друзей
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="star">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.1"
                      d="M10 2.5L12.4922 7.06977L17.6085 8.02786L14.0325 11.8102L14.7023 16.9721L10 14.74L5.29772 16.9721L5.96752 11.8102L2.39155 8.02786L7.50779 7.06977L10 2.5Z"
                      fill="#37307D"
                    />
                  </svg>
                </div>
                <div class="txt">
                  <div class="txt-bold">Подарки</div>
                  <div class="txt-small">
                    Комплект учебной литературы (фирменные ПДД, блокнот, папка,
                    экзаменационные билеты), фотографии
                  </div>
                </div>
              </div>
            </div>
            <!-- Кнопка -->
            <a href="#tariffs" class="btn btn-blue">
              Подобрать тариф
              <div class="svg">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.3335 12.5L12.5002 16.6667L16.6668 12.5"
                    stroke="#F9F9F9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.3335 3.33337H9.16683C10.0509 3.33337 10.8987 3.68456 11.5239 4.30968C12.149 4.93481 12.5002 5.78265 12.5002 6.66671V16.6667"
                    stroke="#F9F9F9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div class="right">
            <div class="title-txt">За 8 лет успешной работы</div>
            <div class="items">
              <div class="row">
                <!-- 1 - Элемент -->
                <div class="i">
                  <div class="txt-bold">более</div>
                  <div class="txt-bold">
                    <span class="txt-big"> 30 000 </span>
                    учеников
                  </div>
                  <div class="txt-small">
                    прошли обучение и получили водительское удостоверение
                  </div>
                </div>
                <!-- 2 - Элемент -->
                <div class="i">
                  <div class="txt-bold">
                    <span class="txt-big"> 20 </span>
                    филиалов
                  </div>
                  <div class="txt-small">во всех районах города</div>
                </div>
                <!-- 3 - Элемент -->
                <div class="i">
                  <div class="txt-bold">
                    <span class="txt-big"> автодром </span>
                  </div>
                  <div class="txt-small">в центре города</div>
                </div>
                <!-- 4 - Элемент -->
                <div class="i">
                  <div class="txt-bold">обучаем на</div>
                  <div class="txt-bold">
                    <span class="txt-big"> все </span>
                    категории
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- 5 - Элемент -->
                <div class="i">
                  <div class="txt-bold">более</div>
                  <div class="txt-bold">
                    <span class="txt-big"> 90 % </span>
                  </div>
                  <div class="txt-small">приходит к нам по рекомендации</div>
                </div>
                <!-- 6 - Элемент -->
                <div class="i">
                  <div class="txt-bold">более</div>
                  <div class="txt-bold">
                    <span class="txt-big"> 45 </span>
                    инструкторов
                  </div>
                  <div class="txt-small">высшей квалификации</div>
                </div>
                <!-- 7 - Элемент -->
                <div class="i">
                  <div class="txt-bold">более</div>
                  <div class="txt-bold">
                    <span class="txt-big"> 60 </span>
                    единиц
                  </div>
                  <div class="txt-small">техники в автопарке</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Live from './Live.vue';
export default {
  name: "About",
  props: {
    msg: String,
  },
  methods:{
    showLive() {
      this.$modal.show(
        Live,
        {},
        {
          name: "live",
          adaptive: true,
          maxWidth: 768,
          width: "100%",
          height: "auto",
        },
        {}
      );
    },
  }
};
</script>

<style lang="scss" scoped>
#about {
  padding: 60px 0;
}
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  max-width: 100%;
  width: 100%;
  .title {
    display:flex;
    flex-direction: column;
    align-items: center;
    font-family: "Futura DemiC";
    font-size: 38px;
    line-height: 1;
    text-align: right;
    color: #151515;
    max-width: 300px;
    width: 100%;
    position: relative;
    margin-bottom:20px;
    // padding: 29px 30px;
    padding: 5px 30px;
    .title-img{
      width:299px;
      height:91px;
      & > img{
        width:100%;
        height:100%;
      }
    }
    .blue{
      text-align:center;
      font-family: "HP Simplified";
      text-transform: uppercase;
      white-space: nowrap;
      color: #37307d;
      font-size: 18px;
      letter-spacing: 6.2px;
      line-height:0.8;
    }
    .yellow {
      text-align: center;
      color: #ffe03a;
      line-height:0.8;
    }
  }
  .title::before {
    content: "";
    background: #0d0739;
    border-radius: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
    width: 1226px;
    height: 104px;
  }
}
.about-img {
  background: url("../assets/img/about-img-new.jpg") no-repeat top center;
  width: 365px;
  height: 540px;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #fff;
  top: 55px;
  left: 0px;
  display:flex;
  justify-content: center;
  align-items: flex-start;
  cursor:pointer;
  &::before{
    content:"";
    position: absolute;
    left:0;
    right:0;
    width:100%;
    height:100%;
    background:#111;
    opacity: 0;
    z-index: 1;
    transition: opacity .5s ease;
  }
  &:hover::before{
    opacity: 0.5;
  }
  & > .text{
    font-family: "Futura DemiC";
    color:#fff;
    font-size:36px;
    max-width: calc(100% - 30px);
    margin-top:calc(40% - 30px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display:flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    & > .svg{
      margin-top:20px;
      width:80px;
      height: 80px;
      transition: all .5s ease;
    }
  }
  &:hover > .text > .svg{
    transform:scale(1.2);
  }
}
.about-bottom {
  display: flex;
  margin-top: 30px;
  width: 68%;
  .left {
    width: 47%;
    margin-right: 36px;
    .items {
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
        width: 100%;
        .star {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
        }
        .txt {
          font-family: "Roboto";
          font-style: normal;
          margin-left: 10px;
          color: #151515;
          font-size: 16px;
          line-height: 19px;
          .txt-bold {
            font-weight: bold;
            margin-bottom: 5px;
          }
          .txt-small {
            font-weight: 300;
          }
          div {
            font-style: normal;
          }
        }
      }
    }
    .btn {
      margin-left: 35px;
      margin-top: 45px;
    }
  }
  .right {
    width: 47%;
    .title-txt {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
    }
    .items {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      flex-wrap: wrap;
      height: 400px;
      .row {
        width: 45.2%;
        .i {
          width: 100%;
          color: #37307d;
          font-family: "Roboto Slab";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 30px;
          margin-right: 35px;
          .txt-bold {
            font-weight: bold;
          }
          .txt-big {
            font-weight: 900;
            font-size: 25px;
            line-height: 33px;
          }
          .txt-small {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1301px) {
  .about-container {
    align-items: flex-start;
  }
  .about-img {
    display: none;
  }
  .about-bottom {
    width: 100%;
    .left {
      .items {
        .item {
          .txt {
            font-size: 14px;
            line-height: 16px;
            .txt-bold {
              line-height: 18px;
            }
          }
        }
      }
    }
    .right {
      .items {
        .row {
          width: 57%;
        }
      }
    }
  }
}
@media screen and (max-width: 1040px) {
  .wrap {
    // padding: 0 100px;
  }
  .about-container {
    .title {
      align-self: center;
      // font-size: 25px;
      // line-height: 33px;
      // max-width: 523px;
    }
  }
  .about-img {
  }
  .about-bottom {
    position: relative;
    .left {
      margin-right: 20px;
      .items {
        .item {
          .txt {
            .txt-bold {
            }
          }
        }
      }
      .btn {
        position: absolute;
        bottom: 0px;
        left: 250px;
        margin: 0px;
      }
    }
    .right {
      .items {
        .row {
          margin-right: 30px;
          width: 53%;
          .i {
            margin-bottom: 20px;
            .txt-bold {
              font-size: 14px;
              line-height: 16px;
            }
            .txt-big {
              font-size: 25px;
              line-height: 33px;
            }
            .txt-small {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 838px) {
  .wrap {
    padding: 0 30px;
  }
}
@media screen and (max-width: 777px) {
  .wrap {
    // padding: 0 15px;
  }
  .about-bottom {
    flex-direction: column;
    margin-top: 30px;
    .left {
      width: 100%;
      .items {
        width: 100%;
      }
      .btn {
        left: 20px;
      }
    }
    .right {
      padding: 0 0 0 20px;
      width: 100%;
      .items {
        .row {
          width: 46%;
        }
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .wrap {
    padding: 0 20px;
  }
  .about-img-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .about-img {
      width: 335px;
      height: 367px;
      border-radius: 5px;
      display: block;
      position: relative;
      top: 0px;
      left: 0px;
      & > .text{
        margin-top:40px;
      }
    }
  }
}
@media screen and (max-width: 457px) {
  .about-bottom {
    .left {
      .btn {
        bottom: -40px;
        left: 0px;
      }
    }
    .right {
      padding: 0px;
    }
  }

  .about-bottom .right .items .row {
    margin-right: 20px;
  }
}
</style>