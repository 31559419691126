<template>
  <div id="popup">
    <div class="popup-container">
      <!-- Верх мод.окна  -->
      <div class="top-popup">
        <div class="txt">{{ el.name }}</div>
        <div class="cost">{{ el.price }}</div>
        <div class="svg">
          <svg
            width="66"
            height="66"
            viewBox="0 0 66 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g filter="url(#filter0_d)">
              <circle cx="33" cy="29" r="29" fill="#0C0738" />
            </g>
            <rect x="18" y="14" width="31" height="30" fill="url(#pattern0)" />
            <defs>
              <filter
                id="filter0_d"
                x="0"
                y="0"
                width="66"
                height="66"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
              >
                <use
                  xlink:href="#image0"
                  transform="scale(0.0322581 0.0333333)"
                />
              </pattern>
              <image
                id="image0"
                width="31"
                height="30"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAYAAADU8sWcAAABo0lEQVRIDdVW0VHDMAzNCIzACGxAR2AENqAb0A1gBDYoG7QjdAO6AXzqOR/mXs4vp9oOtEccIHc6y06i9yTLsrruvz0A7szsoe/720W5xxivAUQn2xjj1SIkAGwS8AHAB3Uz2zUHp4cA3gnY9/2KczM7JgLrpgRCCPcJaC8grQF40VqTUV4SUACMQE5I72YbmeEJ5OiNLgJuZvva3jYHDyHcEJjZnR+r5uBMJoKHEJ59yKkLnKeAR64mALbctvzfb+e+qFDPf/DvU3R8ATrRa+RP7NEYy6YkhPCUjE4epfTPilGoiZmtRazmQJcKxk4f5SONnrC8cKKErdrRSyYVdYlIXIhVfC77BbjbtyKbZwQfynARdrIhCNnllOcCn7TTGlz2ARxy58az2spzV5qLyC4Brh5g8xuev3LP/W04ktCetAr75DEjg9bgyvT8Uhq8bwnua8gYaq+0BP/K9sBB7MzszZOirpDl6+fOXbc7eTF16s94HwN4dKJr0a+dpacbcex2JwmnbmXow+XtjGN5vnMmzMbUCrMo/Fh4j9OpHOdPzD8BZsoHXhZ40VUAAAAASUVORK5CYII="
              />
            </defs>
          </svg>
        </div>
        <div class="close" @click.prevent="closeModal">
          <div class="svg">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.94666 7.10246L15.6986 0.35055L17.4056 2.05758L10.6537 8.80948L18 16.1558L16.1558 18L8.80948 10.6537L2.19475 17.2684L0.487722 15.5614L7.10246 8.94666L0 1.8442L1.8442 0L8.94666 7.10246Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- Низ мод.окна  -->
      <div class="bottom-popup">
        <!-- 1 Столбец -->
        <div class="col">
          <div class="col-title">Теория</div>
          <div>Кол-во часов - <span> {{ el.tk }}</span></div>
          <div v-html="el.modal.textLeft"></div>
        </div>
        <!-- 2 Столбец -->
        <div class="col">
          <div class="col-title">Практика</div>
          <div>Кол-во часов - <span> {{ el.pk }}</span></div>
          <div v-html="el.modal.textCenter"></div>
        </div>
        <!-- 3 Столбец + кнопка -->
        <div class="col">
          <div class="col-title">Сопровождение в ГИБДД</div>
          <div v-html="el.modal.textRight"></div>
          <!-- <div class="btn-container"> -->
          <div class="btn btn-blue" @click="showModal">Выбрать</div>
          <!-- </div> -->
        </div>
        <!-- Конец - столбец -->
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./Popup.vue";
export default {
  name: "Popup",
  props: {
    el: Object,
    msg: String,
  },
  methods: {
    closeModal() {
      this.$modal.hide("tariffpopup");
    },
    showModal() {
      this.$modal.hide("tariffpopup");
      this.$modal.show(
        Popup,
        { text: null, selector: false, message: this.msg },
        {
          name: "popup",
          adaptive: true,
          minWidth: 300,
          maxWidth: 730,
          width: "100%",
          height: "auto",
        },
        {}
      );
    },
  },
};
</script>

<style scoped lang="scss">
#popup {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
}
.popup-container {
  max-width: 819px;
  width: 100%;
  //max-height: 601px;
  //height: 100%;
  .top-popup {
    width: 100%;
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0c0738;
    border-radius: 11px 11px 0px 0px;
    position: relative;
    & > .txt {
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      // letter-spacing: -0.01em;
      color: #ffffff;
      margin-bottom: 8px;
    }
    & > .cost {
      font-weight: 300;
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
    }
    & > .svg {
      position: absolute;
      bottom: -25px;
      left: -500;
      // right: 0;
      width: 58px;
      height: 58px;
    }
    .close {
      position: absolute;
      top: 38px;
      right: 39px;
      .svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .bottom-popup {
    background-color: #fff;
    width: 100%;
    display: flex;
    & > .col {
      max-width: 281px;
      width: 100%;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #0c0738;
      background: #fafafa;
      padding: 48px 42px 42px 40px;
      box-sizing: border-box;
      border-bottom: 2px solid #fafafa;
      .col-title {
        font-weight: 500;
        margin-bottom: 36px;
      }
      & > div > span {
        font-weight: 500;
      }
      & > div {
        margin-bottom: 20px;
      }
    }
    & > .col:nth-child(2) {
      background: #ffffff;
      padding: 48px 19px 42px 13px;
    }
    // Кнопка
    & > .col:nth-child(3) {
      padding: 48px 30px 42px 36px;
      & > div:last-child {
        max-width: 158px;
        width: 100%;
        max-height: 42px;
        height: 100%;
        padding: 13px 50px;
        margin-top: 50px;
      }
    }
  }
}
@media (max-width: 1080px) {
  #popup{
    overflow-y: auto;
    height: 100%;
  }
  .popup-container {
    max-width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    .bottom-popup {
      flex-wrap: wrap;
      .col {
        max-width: 49%;
        padding-bottom: 30px;
        border-bottom: none;
      }
      & > .col:nth-child(2) {
        & > .col {
          padding: 30px 19px 42px 13px;
        }
      }
      & > .col:nth-child(3) {
        & > div:last-child {
          position: absolute;
          bottom: 20px;
          right: 115px;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .popup-container {
    margin: 0 20px;
  }
}
@media (max-width: 615px) {
  .popup-container {
    .bottom-popup {
      .col {
        max-width: 100%;
      }
      & > .col:nth-child(1),
      & > .col:nth-child(2),
      & > .col:nth-child(3) {
        padding: 20px 29px 10px 26px;
      }
      & > .col:nth-child(3) {
        & > div:last-child {
          position: relative;
          bottom: auto;
          right: auto;
          left: auto;
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .popup-container .top-popup .close{
    top: 15px;
    right: 15px;
  }
}
</style>
