<template>
  <section id="tariffs">
    <div class="wrap">
      <div class="tariffs-main">
        <div class="tariffs-selector">
          <h2 class="title">Выберите свой тариф</h2>
          <div class="selector">
            <h3 class="subtitle">Категория</h3>
            <h4 class="labeltitle">Обучение</h4>
            <div class="category">
              <div
                class="category-element"
                :class="[el.id === currentTariff ? 'active' : '']"
                v-for="el in tariffs.filter((e) => e.type === 'o')"
                :key="el.id"
                @click="chooseCategory(el.id)"
              >
                <span>{{ el.name }}</span>
                <inline-svg class="svg" :src="el.svg" />
              </div>
            </div>
          </div>
          <div id="timetrans" class="time-transmission">
            <div class="time">
              <h3 class="subtitle">Срок обучения</h3>
              <div
                class="selector-time"
                v-for="(el, key) in tariffs.filter(
                  (e) => e.id === currentTariff
                )"
                :key="key"
              >
                <div
                  class="selector-time-item"
                  :class="[currentTime === 0 ? 'active' : '']"
                  @click="chooseTime(0)"
                  v-if="el.time.classic != undefined"
                >
                  Классический: {{ el.time.classic.time }}
                </div>
                <div
                  class="selector-time-item"
                  :class="[currentTime === 1 ? 'active' : '']"
                  @click="chooseTime(1)"
                  v-if="el.time.fast != undefined"
                >
                  Ускоренный: {{ el.time.fast.time }}
                </div>
              </div>
            </div>
            <div
              v-if="tariffs[currentTariff].time.classic.mkpp"
              class="transmission"
            >
              <h3 class="subtitle">Автомат / Механика</h3>
              <div class="selector-transmission">
                <div
                  class="selector-transmission-item"
                  :class="[currentTransmission === 1 ? 'active' : '']"
                  @click="chooseTransmission(1)"
                >
                  АКПП (автомат)
                </div>
                <div
                  class="selector-transmission-item"
                  :class="[currentTransmission === 0 ? 'active' : '']"
                  @click="chooseTransmission(0)"
                >
                  МКПП (механика)
                </div>
              </div>
            </div>
            <div v-if="tariffs[currentTariff].pod">
              <h3 class="subtitle">Переподготовка / Обучение</h3>
              <div class="category-pod">
                <div
                  class="category-pod-element"
                  :class="[el.id === currentType ? 'active' : '']"
                  v-for="el in tariffs[currentTariff].pod"
                  :key="el.id"
                  @click="chooseType(el.id)"
                >
                  <span class="pod">{{ el.name }}</span>
                  <inline-svg
                    class="arrow"
                    src="/static/img/svg/arrow-right.svg"
                  />
                  <span class="pod">{{ el.name2 }}</span>
                  <inline-svg class="svg" :src="el.svg" />
                </div>
                <div
                  class="category-pod-element"
                  :class="[currentType === -1 ? 'active' : '']"
                  @click="chooseType(-1)"
                >
                  <span class="pod single">
                    {{ tariffs[currentTariff].name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tariffs-result" v-swiper:swiper="swiperOption">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide tariffs-result-item"
              v-for="(el, key) in currentTariffObject"
              :key="key"
            >
              <div class="top">
                <div class="icon">
                  <img :src="`/static/img/svg/${el.type}.svg`" />
                </div>
                <div class="name">{{ el.name }}</div>
                <div class="price">{{ el.price }}</div>
              </div>
              <div class="bottom">
                <div class="info">
                  <div class="text">{{ el.text }}</div>
                  <div class="tk">
                    Теоретический курс: <span>{{ el.tk }}</span>
                  </div>
                  <div class="pk">
                    Практический курс: <span>{{ el.pk }}</span>
                  </div>
                  <div class="dop">Индивидуальный график вождения</div>
                </div>
                <div class="buttons">
                  <div class="detail" @click="showDetail(el)">Узнать детали</div>
                  <div class="choose" @click="showModal(null, false, el)">
                    Выбрать
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide tariffs-result-item unique">
              <div class="top">
                <div class="icon">
                  <img src="/static/img/svg/plus.svg" alt="" />
                </div>
                <div class="name">Другие услуги</div>
                <div class="list">
                  <vueCustomScrollbar
                    class="scroll-list"
                    tagname="div"
                    name="other"
                    :settings="scrollSet"
                  >
                    <OtherService />
                  </vueCustomScrollbar>
                </div>
                <div class="buttons">
                <div
                  class="choose"
                  @click="
                    showModal(
                      `Хочу получить консультацию и узнать подробности`,
                      true,
                      null
                    )
                  "
                >
                  Выбрать
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination swiper-pagination-bullets pag"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// npm install vue-inline-svg
import Popup from "./Popup.vue";
import TariffsPopup from "./TariffsPopup.vue";
import OtherService from "./OtherService.vue";
import InlineSvg from "vue-inline-svg";
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
} from "swiper/swiper.esm.js";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
SwiperClass.use([Pagination, Navigation]);
const { directive } = getAwesomeSwiper(SwiperClass);

import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "Tariffs",
  components: {
    InlineSvg,
    OtherService,
    vueCustomScrollbar,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      scrollSet: {
        name: "other",
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      currentTariff: 0,
      currentType: 0,
      currentTime: 0,
      currentTransmission: 0,
      swiperOption: {
        slidesPerView: 4,
        updateOnWindowResize: true,
        breakpoints: {
          320: {
            slidesPerView: "auto",
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          },
          1300: {
            slidesPerView: 4,
            simulateTouch: false,
            allowTouchMove: false,
          },
        },
      },
      currentTariffObject: [
        {
          price: "9 990 руб.",
          name: "Профи",
          type: "profi",
          tk: "134 часа",
          pk: "56 часов",
          text:
            "Для тех, у кого уже имеются навыки управления легковым автобилем с МКПП",
          modal:{
            textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li>Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li>Занятия по медицине проводятся специалистом с высшим образованием.</li>",
            textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li>",
            textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li>Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li>На экзамен в ГИБДД к назначенному, удобному времени.</li><li>Подготовка полного пакета документов.</li>"
          }
        },
        {
          price: "29 900 руб.",
          name: "Классический",
          type: "classic",
          tk: "134 часа",
          pk: "56 часов",
          text:
            "Для тех, кто хочет научиться управлять легковым автомобилем с МКПП",
          modal:{
            textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li>Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li>Занятия по медицине проводятся специалистом с высшим образованием.</li>",
            textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li><li>Отработка индивидуального маршрута.</li>",
            textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li>Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li>На экзамен в ГИБДД к назначенному, удобному времени.</li><li>Подготовка полного пакета документов.</li>"
          }
        },
        {
          price: "47 000 руб.",
          name: "VIP",
          type: "vip",
          tk: "134 часа",
          pk: "56 часов",
          text: "Индивидуальный подход, учитывающий все пожелания клиента.",
          modal:{
            textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li>Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li>Занятия по медицине проводятся специалистом с высшим образованием.</li><li>Индивидуальный подход к каждому.</li>",
            textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li><li>Отработка индивидуального, любимого маршрута.</li><li>Вождение на автомобилях премиум класса.</li><li>Индивидуальный подход к каждому.</li>",
            textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li>Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li>На экзамен в ГИБДД к назначенному, удобному времени.</li><li>Подготовка полного пакета документов.</li>"
          }
        },
      ],
      tariffs: [
        {
          id: 0,
          name: "B",
          type: "o",
          svg: "/static/img/svg/i-cat-b.svg",
          time: {
            classic: {
              time: "3 мес",
              mkpp: [
                {
                  price: "9 990 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "134 часа",
                  pk: "56 часов",
                  text:
                    "Для тех, у кого уже имеются навыки управления легковым автобилем с МКПП",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li>Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li>Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li>Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li>На экзамен в ГИБДД к назначенному, удобному времени.</li><li>Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "29 900 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "134 часа",
                  pk: "56 часов",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с МКПП",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li>Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li>Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li><li>Отработка индивидуального маршрута.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li>Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li>На экзамен в ГИБДД к назначенному, удобному времени.</li><li>Подготовка полного пакета документов.</li>"
                  } 
                },
                {
                  price: "47 000 руб.",
                  name: "VIP",
                  type: "vip",
                  tk: "134 часа",
                  pk: "56 часов",
                  text:
                    "Индивидуальный подход, учитывающий все пожелания клиента.",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li>Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li>Занятия по медицине проводятся специалистом с высшим образованием.</li><li>Индивидуальный подход к каждому.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li><li>Отработка индивидуального, любимого маршрута.</li><li>Вождение на автомобилях премиум класса.</li><li>Индивидуальный подход к каждому.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li>Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li>На экзамен в ГИБДД к назначенному, удобному времени.</li><li>Подготовка полного пакета документов.</li>"
                  }
                },
              ],
              akpp: [
                {
                  price: "10 990 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "134 часа",
                  pk: "54 часа",
                  text:
                    "Для тех, у кого уже имеются навыки управления легковым автобилем с АКПП",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "30 900 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "134 часа",
                  pk: "54 часа",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с АКПП",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li>Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li>Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li>Изучение экзаменационного маршрута ГИБДД.</li><li>Отработка индивидуального маршрута.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "47 000 руб.",
                  name: "VIP",
                  type: "vip",
                  tk: "134 часа",
                  pk: "54 часа",
                  text:
                    "Индивидуальный подход, учитывающий все пожелания клиента.",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием. Индивидуальный подход к каждому.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li><li> Отработка индивидуального, любимого маршрута.</li><li> Вождение на автомобилях премиум класса.</li><li> Индивидуальный подход к каждому.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
            fast: {
              time: "39 дней",
              mkpp: [
                {
                  price: "9 990 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "134 часа",
                  pk: "56 часов",
                  text:
                    "Для тех, у кого уже имеются навыки управления легковым автобилем с МКПП и он хочет получить водительское удостоверение в максимально короткий срок",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "32 900 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "134 часа",
                  pk: "56 часов",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с МКПП в максимально короткий срок",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li><li> Отработка индивидуального маршрута.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "47 000 руб.",
                  name: "VIP",
                  type: "vip",
                  tk: "134 чса",
                  pk: "56 часов",
                  text:
                    "Индивидуальный подход, учитывающий все пожелания клиента.",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием. Индивидуальный подход к каждому.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li><li> Отработка индивидуального, любимого маршрута.</li><li> Вождение на автомобилях премиум класса.</li><li> Индивидуальный подход к каждому.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
              akpp: [
                {
                  price: "10 990 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "134 часа",
                  pk: "54 часа",
                  text:
                    "Для тех, у кого уже имеются навыки управления легковым автобилем с АКПП и он хочет получить водительское удостоверение в максимально короткий срок",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "33 900 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "134 часа",
                  pk: "54 часа",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с АКПП в максимально короткий срок",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li><li> Отработка индивидуального маршрута.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "47 000 руб.",
                  name: "VIP",
                  type: "vip",
                  tk: "134 часа",
                  pk: "54 часа",
                  text:
                    "Индивидуальный подход, учитывающий все пожелания клиента.",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием. </li><li> Индивидуальный подход к каждому.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Начало и конец занятий от филиала автошколы, а не от удобного для инструктора места.</li><li> Возможность позаниматься на любом автомобиле из собственного автопарка во время обучения бесплатно, хоть на всех.</li><li> Изучение экзаменационного маршрута ГИБДД.</li><li> Отработка индивидуального, любимого маршрута.</li><li> Вождение на автомобилях премиум класса.</li><li> Индивидуальный подход к каждому.</li>",
                    textRight:"<li>Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
        {
          id: 1,
          name: "BE",
          type: "o",
          svg: "/static/img/svg/i-cat-be.svg",
          time: {
            classic: {
              time: "1,5 мес",
              mkpp: [
                {
                  price: "9 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "16 часов",
                  pk: "16 часов",
                  text:
                    "Для тех, у кого уже открыта категория В в водительском удостоверении (не менее 1 года) и имеются навыки управления легковым автомобилем с прицепом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "13 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "16 часов",
                  pk: "16 часов",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с прицепом и открыта категория В в водительском удостоверении (не менее 1 года)",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
              akpp: [
                {
                  price: "9 900 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "16 часов",
                  pk: "16 часов",
                  text:
                    "Для тех, у кого уже открыта категория В в водительском удостоверении (не менее 1 года) и имеются навыки управления легковым автомобилем с прицепом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "13 900 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "16 часов",
                  pk: "16 часов",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с прицепом и открыта категория В в водительском удостоверении (не менее 1 года)",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
        {
          id: 2,
          name: "С",
          type: "o",
          svg: "/static/img/svg/i-cat-c.svg",
          time: {
            classic: {
              time: "2,5 мес",
              other: [
                {
                  price: "20 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "172 часа ",
                  pk: "72 часа",
                  text:
                    "Для тех, у кого уже имеются навыки управления грузовым автомобилем",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "32 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "172 часа",
                  pk: "72 часа",
                  text:
                    "Для тех, кто хочет научиться управлять грузовым автомобилем ",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
          pod: [
            {
              id: 0,
              name: "B",
              name2: "C",
              type: "p",
              svg: "/static/img/svg/i-cat-c.svg",
              price: [
                {
                  price: "14 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "42 часа",
                  pk: "38 часов",
                  text:
                    "Для тех, у кого уже есть водительское удостоверение с открытой категорией В и имеются навыки управления грузовым автомобилем",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "19 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "42 часа",
                  pk: "38 часов",
                  text:
                    "Для тех, кто хочет научиться управлять грузовым автомобилем и уже есть водительское удостоверение с открытой категорией В",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          ],
        },
        {
          id: 3,
          name: "СE",
          type: "o",
          svg: "/static/img/svg/i-cat-be.svg",
          time: {
            classic: {
              time: "1,5 мес",
              other: [
                {
                  price: "10 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "16 часов",
                  pk: "24 часа",
                  text:
                    "Для тех, у кого уже открыта категория С в водительском удостоверении (не менее 1 года) и имеются навыки управления грузовым автомобилем с прицепом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "17 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "16 часов",
                  pk: "24 часа",
                  text:
                    "Для тех, кто хочет научиться управлять грузовым автомобилем с прицепом и открыта категория С в водительском удостоверении (не менее 1 года)",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
        {
          id: 4,
          name: "D",
          type: "o",
          svg: "/static/img/svg/i-cat-d.svg",
          time: {
            classic: {
              time: "3 мес",
              other: [
                {
                  price: "30 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "196 часов",
                  pk: "100 часов",
                  text:
                    "Для тех, у кого уже имеются навыки управления автобусом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "38 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "196 часов",
                  pk: "100 часов",
                  text: "Для тех, кто хочет научиться управлять автобусом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
          pod: [
            {
              id: 0,
              name: "C",
              name2: "D",
              type: "p",
              svg: "/static/img/svg/i-cat-d.svg",
              price: [
                {
                  price: "18 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "74 часа",
                  pk: "40 часов",
                  text:
                    "Для тех, у кого уже есть водительское удостоверение с открытой категорией С и имеются навыки управления автобусом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "24 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "74 часа",
                  pk: "40 часов",
                  text:
                    "Для тех, кто хочет научиться управлять автобусом и уже есть водительское удостоверение с открытой категорией С",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
            {
              id: 1,
              name: "B",
              name2: "D",
              type: "p",
              svg: "/static/img/svg/i-cat-d.svg",
              price: [
                {
                  price: "21 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "78 часов",
                  pk: "74 часа",
                  text:
                    "Для тех, у кого уже есть водительское удостоверение с открытой категорией В и имеются навыки управления автобусом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "28 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "78 часов",
                  pk: "74 часа",
                  text:
                    "Для тех, кто хочет научиться управлять автобусом и уже есть водительское удостоверение с открытой категорией В",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          ],
        },
        {
          id: 5,
          name: "DE",
          type: "o",
          svg: "/static/img/svg/i-cat-be.svg",
          time: {
            classic: {
              time: "1,5 мес",
              other: [
                {
                  price: "10 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "16 часов",
                  pk: "32 часа",
                  text:
                    "Для тех, у кого уже имеются навыки управления легковым автобилем с МКПП",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "18 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "16 часов",
                  pk: "32 часа",
                  text:
                    "Для тех, кто хочет научиться управлять легковым автомобилем с МКПП",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Изучение экзаменационного маршрута ГИБДД.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление автомобилей для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
        {
          id: 6,
          name: "A",
          type: "o",
          svg: "/static/img/svg/i-cat-a.svg",
          time: {
            classic: {
              time: "2 мес",
              other: [
                {
                  price: "6 900 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "112 часов",
                  pk: "18 часов",
                  text:
                    "Для тех, у кого уже имеются навыки управления мотоциклом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Возможность позаниматься на любом мотоцикле из собственного мотопарка во время обучения бесплатно, хоть на всех.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "11 800 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "112 часов",
                  pk: "18 часов",
                  text: "Для тех, кто хочет научиться управлять мотоциклом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Возможность позаниматься на любом мотоцикле из собственного мотопарка во время обучения бесплатно, хоть на всех.</li><li> Занятия с имитацией городских дорожных условий.</li><li> Начальная контраварийная подготовка.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
            fast: {
              time: "35 дней",
              other: [
                {
                  price: "8 900 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "112 часов",
                  pk: "18 часов",
                  text:
                    "Для тех, у кого уже имеются навыки управления мотоциклом и он хочет получить водительское удостоверение в максимально короткий срок",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Возможность позаниматься на любом мотоцикле из собственного мотопарка во время обучения бесплатно, хоть на всех.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "13 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "112 часов",
                  pk: "18 часов",
                  text:
                    "Для тех, кто хочет научиться управлять мотоциклом в максимально короткий срок",
                  
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Возможность позаниматься на любом мотоцикле из собственного мотопарка во время обучения бесплатно, хоть на всех.</li><li> Занятия с имитацией городских дорожных условий.</li><li> Начальная контраварийная подготовка.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
        {
          id: 7,
          name: "A1",
          type: "o",
          svg: "/static/img/svg/i-cat-a.svg",
          time: {
            classic: {
              time: "2 мес",
              other: [
                {
                  price: "6 900 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "112 часов",
                  pk: "18 часов",
                  text:
                    "Для тех, у кого уже имеются навыки управления мотоциклом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Возможность позаниматься на любом мотоцикле из собственного мотопарка во время обучения бесплатно, хоть на всех.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "10 800 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "112 часов",
                  pk: "18 часов",
                  text: "Для тех, кто хочет научиться управлять мотоциклом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Возможность позаниматься на любом мотоцикле из собственного мотопарка во время обучения бесплатно, хоть на всех.</li><li> Занятия с имитацией городских дорожных условий.</li><li> Начальная контраварийная подготовка.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
        {
          id: 8,
          name: "M",
          type: "o",
          svg: "/static/img/svg/i-cat-a.svg",
          time: {
            classic: {
              time: "2 мес",
              other: [
                {
                  price: "6 400 руб.",
                  name: "Профи",
                  type: "profi",
                  tk: "104 часа",
                  pk: "18 часов",
                  text: "Для тех, у кого уже имеются навыки управления мопедом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
                {
                  price: "8 400 руб.",
                  name: "Классический",
                  type: "classic",
                  tk: "104 часа",
                  pk: "18 часов",
                  text: "Для тех, кто хочет научиться управлять мопедом",
                  modal:{
                    textLeft:"<li>Online/очно (группы: утренние, дневные, вечерние, выходного дня).</li><li> Возможность бесплатно посещать пропущенные лекции в других филиалах.</li><li> Занятия по медицине проводятся специалистом с высшим образованием.</li>",
                    textCenter:"<li>Удобное время практических занятий с 7:00 до 20:00, включая субботу и воскресенье.</li><li> Занятия с имитацией городских дорожных условий.</li><li> Начальная контраварийная подготовка.</li>",
                    textRight:"<li>Экзамен ГИБДД на собственном автодроме С.А.</li><li> Предоставление мототехники для сдачи экзамена.</li><li> Поддержка представителя автошколы, отстаивание интересов ученика на экзамене.</li><li> На экзамен в ГИБДД к назначенному, удобному времени.</li><li> Подготовка полного пакета документов.</li>"
                  }
                },
              ],
            },
          },
        },
      ],
    };
  },
  methods: {
    createMessage(el) {
      let msg = "<ul>";
      let tariffName =
        "<li>Категория: " + this.tariffs[this.currentTariff].name + "</li>";
      let tariffType = "";
      if (this.tariffs[this.currentTariff].pod && this.currentType != -1) {
        tariffType =
          "<li>Тип: переподготовка " +
          this.tariffs[this.currentTariff].pod[this.currentType].name +
          " -> " +
          this.tariffs[this.currentTariff].pod[this.currentType].name2;
      } else {
        tariffType = "<li>Тип: обучение</li>";
      }
      let choosen = "<li>" + el.name + " - " + el.price + "</li>";
      msg += tariffName + tariffType + choosen + "</ul>";

      return msg;
    },
    showDetail(el) {
      console.log(el);
      this.$modal.show(
        TariffsPopup, // component
        { el: el, msg: this.createMessage(el) }, // component props
        {
          // settings this.$modal
          name: "tariffpopup",
          adaptive: true,
          minWidth: 300,
          maxWidth: 820,
          width: "100%",
          height: "auto",
          scrollable:true,
        },
        {}
      );
    },
    showModal(title, hasSelector = false, el = null) {
      let msg = "";
      if (el) {
        msg = this.createMessage(el);
      } else {
        msg = "";
      }

      this.$modal.show(
        Popup,
        { text: title, selector: hasSelector, message: msg },
        {
          name: "popup",
          adaptive: true,
          minWidth: 300,
          maxWidth: 730,
          width: "100%",
          height: "auto",
        },
        {}
      );
    },
    chooseCategory(id) {
      this.currentTariff = id;
      this.currentTime = 0;
      this.currentTransmission = 0;
      this.setupCurrentTarif(id);
      this.chooseType(0);

      let marker = document.getElementById("timetrans");
      this.$SmoothScroll(marker, 500, {}, undefined, "y");
    },
    chooseType(id) {
      this.currentType = id;
      if (id === -1) {
        this.setupCurrentTarif(this.currentTariff);
      } else {
        if (this.tariffs[this.currentTariff].pod) {
          this.currentTariffObject = this.tariffs[this.currentTariff].pod[
            id
          ].price;
        }
      }
    },
    chooseTime(id) {
      this.currentTime = id;
      this.setupCurrentTarif(id);
    },
    chooseTransmission(id) {
      this.currentTransmission = id;
      this.setupCurrentTarif(id);
    },
    setupCurrentTarif(id) {
      if (this.tariffs[this.currentTariff].time.classic.other === undefined) {
        if (this.currentTime === 0) {
          if (this.currentTransmission === 0)
            this.currentTariffObject = this.tariffs[
              this.currentTariff
            ].time.classic.mkpp;
          else
            this.currentTariffObject = this.tariffs[
              this.currentTariff
            ].time.classic.akpp;
        } else {
          if (this.currentTransmission === 0)
            this.currentTariffObject = this.tariffs[
              this.currentTariff
            ].time.fast.mkpp;
          else
            this.currentTariffObject = this.tariffs[
              this.currentTariff
            ].time.fast.akpp;
        }
      } else {
        if (this.currentTime === 0)
          this.currentTariffObject = this.tariffs[
            this.currentTariff
          ].time.classic.other;
        else
          this.currentTariffObject = this.tariffs[
            this.currentTariff
          ].time.fast.other;
      }

      //console.log(this.currentTariffObject);
    },
  },
  mounted() {
    //console.log("Current Swiper instance object", this.swiper);
  },
};
</script>

<style lang="scss">
.pag {
  bottom: 10px;
  & > .swiper-pagination-bullet {
    background: #f9f9f9;
    opacity: 0.2;
  }
  & > .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
#tariffs {
  background: url("/static/img/tarrifs-bg.jpg") no-repeat top center;
  background-size: cover;
  width: 100%;
}
.tariffs-main {
  padding: 20px 18px 330px;
  position: relative;
  .title {
    font-family: "Roboto Slab", serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 1.3;
    color: #f9f9f9;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .subtitle {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 1.16;
    color: #f9f9f9;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .labeltitle {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.15;
    color: #f9f9f9;
    opacity: 0.5;
    margin-bottom: 15px;
  }
}
.tariffs-selector {
  .category,
  .category-pod {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .category-element,
    .category-pod-element {
      display: flex;
      align-items: center;
      border-radius: 30px;
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 0 20px;
      height: 50px;
      background-color: #2a2562;
      transition: all 0.3s ease;
      width: auto;
      max-width: 120px;
      box-sizing: border-box;
      cursor: pointer;
      .svg path,
      .svg circle,
      .svg ellipse,
      .arrow path {
        stroke: #f9f9f9;
      }
      .arrow {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 15px;
      }
      & > span {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 1.18;
        margin-right: 5px;
        color: #fff;
        &.pod {
          margin-right: 15px;
        }
      }
      &:hover,
      &.active {
        background-color: #fff;
        & > span {
          color: #37307d;
        }
        .svg path,
        .svg circle,
        .svg ellipse,
        .arrow path {
          stroke: #37307d;
        }
      }
    }
    .category-pod-element {
      height: 40px;
      & > span.pod {
        font-size: 20px;
        &.single {
          margin-right: 0;
        }
      }
    }
  }
  .time-transmission {
    display: flex;
    flex-wrap: wrap;
    & > .time {
      margin-right: 50px;
    }
  }
}
.selector-time,
.selector-transmission {
  display: flex;
  margin-bottom: 50px;
  & > .selector-time-item,
  & > .selector-transmission-item {
    cursor: pointer;
    padding: 11px 25px;
    border-radius: 30px;
    background-color: #2a2562;
    transition: all 0.3s ease;
    margin-right: 20px;
    color: #fff;
    font-family: "Roboto";
    font-weight: normal;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    &.active {
      color: #37307d;
      background-color: #f9f9f9;
    }
  }
}
.tariffs-result {
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  .tariffs-result-item {
    background: #f9f9f9;
    border-radius: 10px;
    margin-right: 30px;
    min-width: 260px;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 480px;

    & > .top {
      background-color: #f9f9f9;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      & > .icon {
        position: absolute;
        top: -29px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 58px;
        height: 58px;
        background-color: #0c0738;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
        & > img {
          width: 30px;
          height: 30px;
        }
      }
      & > .name {
        margin-top: 40px;
        margin-bottom: 24px;
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 26px;
        line-height: 1.16;
        text-align: center;
        color: #37307d;
      }
      & > .price {
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        font-size: 30px;
        line-height: 1.16;
        text-align: center;
        color: #37307d;
        margin-bottom: 25px;
      }
    }
    & > .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      flex-grow: 1;
      background-color: rgba(55, 48, 125, 0.1);
      & > .info {
        & > .text {
          text-align: center;
          padding: 10px 25px;
          font-family: "Roboto";
          font-size: 14px;
          line-height: 1.14;
          text-align: center;
          color: #37307d;
        }
        & > .tk {
          text-align: center;
          color: rgba(55, 48, 125, 0.5);
          margin-bottom: 10px;
          & > span {
            color: #c03030;
          }
        }
        & > .pk {
          text-align: center;
          color: rgba(55, 48, 125, 0.5);
          margin-bottom: 10px;
          & > span {
            color: #c03030;
          }
        }
        & > .dop {
          text-align: center;
          font-family: "Roboto";
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #37307d;
        }
      }
    }
    &.unique {
      position: relative;
      & > .top {
        position: relative;
        height: 100%;
        min-height: 480px;
        border-radius: 10px;
        & > .choose {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .list {
      width: 100%;
      height: 250px;
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .buttons{
      width: calc(100% - 88px);
      margin-bottom: 30px;
    }
    .detail {
        border: 1px solid #37307d;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        width:100%;
        text-align: center;
        padding: 15px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        color: #37307d;
        margin-bottom: 10px;
    }
    .choose {
      cursor: pointer;
      background: #37307d;
      border-radius: 5px;
      width:100%;
      text-align: center;
      color: #fff;
      padding: 15px 20px;
      box-sizing: border-box;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 1.14;
    }
  }
}

.scroll-list {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 1300px) {
  .tariffs-main {
    padding: 10px 0px 330px;
  }
  .tariffs-result .tariffs-result-item {
    min-width: 295px;
    max-width: 295px;
  }

  .tariffs-selector {
    .category,
    .category-pod {
      .category-element,
      .category-pod-element {
        padding: 0 15px;
        margin-right: 10px;
        .svg {
          width: 40px;
          height: 40px;
        }
      }
      .category-pod-element {
        .svg {
          display: none;
        }
        .arrow {
          width: 24px;
          height: 24px;
          margin: 0 10px;
        }
        span.pod {
          margin: 0px;
        }
      }
    }
  }
  .selector-time,
  .selector-transmission {
    margin-bottom: 5px;
  }
}
@media (max-width: 768px) {
  .tariffs-main .title {
    font-size: 26px;
  }
  .tariffs-main .subtitle {
    font-size: 23px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .tariffs-main .labeltitle {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
@media (max-width: 374px) {
  .tariffs-result .tariffs-result-item {
    max-width: 265px;
    min-width: 265px;
  }
}
</style>
