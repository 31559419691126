<template>
  <div id="app">
    <Main />
    <!-- <TariffsPopup /> -->
    <About />
    <Why />
    <Tariffs />
    <Map />
    <Info />
    <Reviews />
    <Footer />
  </div>
</template>

<script>
import Popup from "./components/Popup.vue";
import Header from "./components/Header.vue";
import Navigation from "./components/Navigation.vue";
import Main from "./components/Main.vue";
import About from "./components/About.vue";
import Why from "./components/Why.vue";
import Tariffs from "./components/Tariffs.vue";
import TariffsPopup from "./components/TariffsPopup.vue";
import Map from "./components/Map.vue";
import Info from "./components/Info.vue";
import Reviews from "./components/Reviews.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Popup,
    Header,
    Navigation,
    Main,
    About,
    Why,
    Tariffs,
    TariffsPopup,
    Map,
    Info,
    Reviews,
    Footer,
  },
  mounted() {
    if (this.$route.query) {
      let utms = this.$route.query;
      if (!localStorage.haveUTM && this.$route.query.utm_campaign) {
        localStorage.haveUTM = "Y";
        localStorage.utm_source = utms.utm_source;
        localStorage.utm_campaign = utms.utm_campaign;
        localStorage.utm_content = utms.utm_content;
        localStorage.utm_medium = utms.utm_medium;
        localStorage.utm_term = utms.utm_term;
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem("haveUTM");
    localStorage.removeItem("utm_source");
    localStorage.removeItem("utm_campaign");
    localStorage.removeItem("utm_content");
    localStorage.removeItem("utm_medium");
    localStorage.removeItem("utm_term");
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Futura DemiC";
  src: url("~@/assets/font/FuturaDemiC.ttf");
}
@font-face {
  font-family: "HP Simplified";
  src: url("~@/assets/font/HP-Simplified.ttf");
}
html {
  scroll-behavior: smooth;
}
.vm--modal {
  background-color: transparent !important;
}
#app {
  font-family: "Roboto", sans-serif;
}
body {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}
.wrap {
  max-width: 1200px;
  margin: 0 auto;
}
svg,
img {
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: #000;
}
// Форма
input {
  padding: 13px 15px;
  max-width: 314px;
  width: 100%;
  max-height: 46px;
  height: 100%;
  background: #f7f7f7;
  border: 1.5px solid #dcdaed;
  box-sizing: border-box;
  border-radius: 5px;
}
input::placeholder {
  opacity: 0.5;
}
input:checked {
}
// Кнопки
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 20px;
  max-width: 314px;
  width: 100%;
  max-height: 56px;
  height: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
  border: none;
  transition: 0.3s ease;
  .svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}
.btn-yellow {
  background: #ffe03a;
  color: #151515;
}
.btn-blue {
  background: #37307d;
  color: #f9f9f9;
}
.btn-yellow:hover {
  background: #ffc700;
}
.btn-blue:hover {
  background: #0c0738;
}
// popup
.popup {
  position: relative;
  display: relative;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .wrap {
    padding: 0 130px;
  }
}
@media screen and (max-width: 1040px) {
  .wrap {
    padding: 0 100px;
  }
}
@media screen and (max-width: 989px) {
  .wrap {
    padding: 0 60px;
  }
}
@media screen and (max-width: 684px) {
  .wrap {
    padding: 0 20px;
  }
}
</style>
