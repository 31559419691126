<template>
  <section id="why">
    <div class="wrap">
      <div class="why-container">
        <!-- Верх блока -->
        <div class="title">
          Почему
          <span> вам понравится </span>
          учиться в нашей автошколе
        </div>
        <!-- Середина блока -->
        <div class="why-center">
          <div class="cards">
            <div class="row">
              <div class="card">
                <div class="svg">
                  <svg
                    width="171"
                    height="70"
                    viewBox="0 0 171 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M160.421 58.7H75.3206"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M49.5207 58.7H10.4207"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M145.121 38.9L141.721 32.4C141.421 31.9 140.821 31.6 140.221 31.6H130.821C130.221 31.6 129.721 31.9 129.321 32.4L125.921 38.9"
                      fill="#FFE03A"
                    />
                    <path
                      d="M145.121 38.9L141.721 32.4C141.421 31.9 140.821 31.6 140.221 31.6H130.821C130.221 31.6 129.721 31.9 129.321 32.4L125.921 38.9"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M148.021 49.2H123.121V42.3C123.121 40.4 124.621 38.9 126.521 38.9H144.721C146.621 38.9 148.121 40.4 148.121 42.3V49.2H148.021Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M146.721 52.7H143.921C143.321 52.7 142.921 52.3 142.821 51.7L142.521 49.2H148.021L147.721 51.7C147.721 52.2 147.321 52.7 146.721 52.7Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M127.221 52.7H124.421C123.821 52.7 123.421 52.3 123.321 51.7L123.021 49.2H128.521L128.221 51.7C128.321 52.2 127.821 52.7 127.221 52.7Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M140.721 49.2L138.121 45H135.621H133.021L130.521 49.2"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M142.821 45.1C143.539 45.1 144.121 44.518 144.121 43.8C144.121 43.082 143.539 42.5 142.821 42.5C142.103 42.5 141.521 43.082 141.521 43.8C141.521 44.518 142.103 45.1 142.821 45.1Z"
                      fill="#37307D"
                    />
                    <path
                      d="M128.321 45.1C129.039 45.1 129.621 44.518 129.621 43.8C129.621 43.082 129.039 42.5 128.321 42.5C127.603 42.5 127.021 43.082 127.021 43.8C127.021 44.518 127.603 45.1 128.321 45.1Z"
                      fill="#37307D"
                    />
                    <path
                      d="M37.2207 33V37.5"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M39.5208 35.3H35.0208"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M119.421 25.7V28.7"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M120.921 27.2H117.921"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M56.1208 39.6C55.9208 41.2 55.6208 43.4 55.3208 45.1C55.2208 45.8 55.0208 46.4 54.9208 46.8C54.4208 48.3 52.4208 52.7 51.0208 55.7H39.0208C39.1208 55.3 39.2208 54.8 39.2208 54.4C39.2208 52 37.3208 50.1 34.9208 50.1C32.5208 50.1 30.6208 52 30.6208 54.4C30.6208 54.9 30.7208 55.3 30.8208 55.7H27.0208V48.4C27.0208 48.4 31.9208 45.1 35.9208 45.1H39.6208C39.6208 45.1 44.5208 39.6 54.7208 39.6C55.3208 39.6 55.7208 39.6 56.1208 39.6Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M55.2207 45.1H54.8207H39.7207"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.8208 46.8V45.1V39.6"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M51.2208 48.1H47.8208"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M39.2207 54.4C39.2207 54.9 39.1207 55.3 39.0207 55.7C38.5207 57.4 36.8207 58.7 35.0207 58.7C33.1207 58.7 31.5207 57.5 30.9207 55.7C30.8207 55.3 30.7207 54.8 30.7207 54.4C30.7207 52 32.6207 50.1 35.0207 50.1C37.3207 50.1 39.2207 52 39.2207 54.4Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M89.9207 46L89.2207 58.7H91.4207L93.6207 48.7L95.3207 58.7H98.6207L97.3207 46H89.9207Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M89.9206 46V40.7L82.2206 44.5L80.8206 41.7L89.9206 36.7H95.1206L97.3206 46H89.9206Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M75.3208 58.7C72.8208 58.7 65.6208 60.6 59.7208 60.7C53.8208 60.8 49.5208 58.7 49.5208 58.7C49.5208 58.7 50.1208 57.5 50.9208 55.7C52.3208 52.7 54.4208 48.2 54.8208 46.8C54.9208 46.4 55.1208 45.8 55.2208 45.1C55.5208 43.4 55.9208 41.2 56.0208 39.6C56.1208 38.5 56.2208 37.8 56.2208 37.8C64.8208 37.5 72.1208 42.8 72.1208 42.8L80.1208 43.7C80.7208 53.4 77.8208 58.7 75.3208 58.7Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M61.4208 45.1C61.3208 49.6 57.0208 57.5 49.5208 58.7C49.5208 58.7 58.0208 59.3 62.4208 53.9"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M75.6206 51.4001C79.4206 49.2001 80.2206 43.8 80.2206 43.8C80.2206 43.8 77.4206 46.2 73.8206 46"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M92.2207 33.5C90.8207 33.5 89.7207 32.4 89.7207 31V29.7C89.7207 28.3 90.8207 27.2 92.2207 27.2C93.6207 27.2 94.7207 28.3 94.7207 29.7V31C94.7207 32.4 93.6207 33.5 92.2207 33.5Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.2205 37.6H22.7205"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.8206 37.6H17.3206"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.7205 41.1V39.6"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.7205 35.7V34.2"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M154.921 22H153.421"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M149.521 22H148.021"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M151.521 25.4V23.9"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M151.521 20.1V18.6"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M151.621 52.7H119.521C118.621 52.7 117.921 53.3 117.721 54.2L116.921 58.7H154.121L153.321 54.2C153.221 53.3 152.521 52.7 151.621 52.7Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="title">Собственный автопарк</div>
                <div class="txt">
                  Возможность попробовать позаниматься на разных автомобилях
                </div>
              </div>
              <div class="card">
                <div class="svg">
                  <svg
                    width="171"
                    height="70"
                    viewBox="0 0 171 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M160.421 58.7H10.4209"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M44.3211 53.7001C51.6113 53.7001 57.5211 47.7902 57.5211 40.5C57.5211 33.2099 51.6113 27.3 44.3211 27.3C37.0309 27.3 31.1211 33.2099 31.1211 40.5C31.1211 47.7902 37.0309 53.7001 44.3211 53.7001Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M44.3213 35.1001V40.5001L47.0213 43.2001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M53.2212 41.5C53.7735 41.5 54.2212 41.0523 54.2212 40.5C54.2212 39.9477 53.7735 39.5 53.2212 39.5C52.6689 39.5 52.2212 39.9477 52.2212 40.5C52.2212 41.0523 52.6689 41.5 53.2212 41.5Z"
                      fill="#37307D"
                    />
                    <path
                      d="M52.021 37.1001C52.5733 37.1001 53.021 36.6524 53.021 36.1001C53.021 35.5478 52.5733 35.1001 52.021 35.1001C51.4687 35.1001 51.021 35.5478 51.021 36.1001C51.021 36.6524 51.4687 37.1001 52.021 37.1001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M44.3213 32.6001C44.8736 32.6001 45.3213 32.1524 45.3213 31.6001C45.3213 31.0478 44.8736 30.6001 44.3213 30.6001C43.769 30.6001 43.3213 31.0478 43.3213 31.6001C43.3213 32.1524 43.769 32.6001 44.3213 32.6001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M38.021 35.2001C38.5733 35.2001 39.021 34.7524 39.021 34.2001C39.021 33.6478 38.5733 33.2001 38.021 33.2001C37.4687 33.2001 37.021 33.6478 37.021 34.2001C37.021 34.7524 37.4687 35.2001 38.021 35.2001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M35.3213 41.5C35.8736 41.5 36.3213 41.0523 36.3213 40.5C36.3213 39.9477 35.8736 39.5 35.3213 39.5C34.769 39.5 34.3213 39.9477 34.3213 40.5C34.3213 41.0523 34.769 41.5 35.3213 41.5Z"
                      fill="#37307D"
                    />
                    <path
                      d="M38.021 47.8C38.5733 47.8 39.021 47.3523 39.021 46.8C39.021 46.2478 38.5733 45.8 38.021 45.8C37.4687 45.8 37.021 46.2478 37.021 46.8C37.021 47.3523 37.4687 47.8 38.021 47.8Z"
                      fill="#37307D"
                    />
                    <path
                      d="M44.3213 50.4C44.8736 50.4 45.3213 49.9523 45.3213 49.4C45.3213 48.8477 44.8736 48.4 44.3213 48.4C43.769 48.4 43.3213 48.8477 43.3213 49.4C43.3213 49.9523 43.769 50.4 44.3213 50.4Z"
                      fill="#37307D"
                    />
                    <path
                      d="M50.6211 47.8C51.1734 47.8 51.6211 47.3523 51.6211 46.8C51.6211 46.2478 51.1734 45.8 50.6211 45.8C50.0688 45.8 49.6211 46.2478 49.6211 46.8C49.6211 47.3523 50.0688 47.8 50.6211 47.8Z"
                      fill="#37307D"
                    />
                    <path
                      d="M35.3213 41.5C35.8736 41.5 36.3213 41.0523 36.3213 40.5C36.3213 39.9477 35.8736 39.5 35.3213 39.5C34.769 39.5 34.3213 39.9477 34.3213 40.5C34.3213 41.0523 34.769 41.5 35.3213 41.5Z"
                      fill="#37307D"
                    />
                    <path
                      d="M44.3213 50.4C44.8736 50.4 45.3213 49.9523 45.3213 49.4C45.3213 48.8477 44.8736 48.4 44.3213 48.4C43.769 48.4 43.3213 48.8477 43.3213 49.4C43.3213 49.9523 43.769 50.4 44.3213 50.4Z"
                      fill="#37307D"
                    />
                    <path
                      d="M52.021 36.1001C52.821 37.4001 53.221 38.9001 53.221 40.6001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M37.0209 52.7001L34.9209 58.7001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M51.521 52.7001L53.621 58.7001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M83.6211 23.9V20.3"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M107.521 23.9V20.3"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M80.8208 58.7001V53.2001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M110.321 58.7001V53.2001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M88.521 41.3H95.421"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M88.8207 36C89.4282 36 89.9207 35.5076 89.9207 34.9001C89.9207 34.2925 89.4282 33.8 88.8207 33.8C88.2132 33.8 87.7207 34.2925 87.7207 34.9001C87.7207 35.5076 88.2132 36 88.8207 36Z"
                      fill="#37307D"
                    />
                    <path
                      d="M95.4208 36C96.0283 36 96.5208 35.5076 96.5208 34.9001C96.5208 34.2925 96.0283 33.8 95.4208 33.8C94.8133 33.8 94.3208 34.2925 94.3208 34.9001C94.3208 35.5076 94.8133 36 95.4208 36Z"
                      fill="#37307D"
                    />
                    <path
                      d="M101.921 36C102.528 36 103.021 35.5076 103.021 34.9001C103.021 34.2925 102.528 33.8 101.921 33.8C101.313 33.8 100.821 34.2925 100.821 34.9001C100.821 35.5076 101.313 36 101.921 36Z"
                      fill="#37307D"
                    />
                    <path
                      d="M108.521 36C109.128 36 109.621 35.5076 109.621 34.9001C109.621 34.2925 109.128 33.8 108.521 33.8C107.913 33.8 107.421 34.2925 107.421 34.9001C107.421 35.5076 107.913 36 108.521 36Z"
                      fill="#37307D"
                    />
                    <path
                      d="M101.921 42.4001C102.528 42.4001 103.021 41.9076 103.021 41.3001C103.021 40.6926 102.528 40.2001 101.921 40.2001C101.313 40.2001 100.821 40.6926 100.821 41.3001C100.821 41.9076 101.313 42.4001 101.921 42.4001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M108.521 42.4001C109.128 42.4001 109.621 41.9076 109.621 41.3001C109.621 40.6926 109.128 40.2001 108.521 40.2001C107.913 40.2001 107.421 40.6926 107.421 41.3001C107.421 41.9076 107.913 42.4001 108.521 42.4001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M95.4208 48.7C96.0283 48.7 96.5208 48.2075 96.5208 47.6C96.5208 46.9925 96.0283 46.5 95.4208 46.5C94.8133 46.5 94.3208 46.9925 94.3208 47.6C94.3208 48.2075 94.8133 48.7 95.4208 48.7Z"
                      fill="#37307D"
                    />
                    <path
                      d="M101.921 48.7C102.528 48.7 103.021 48.2075 103.021 47.6C103.021 46.9925 102.528 46.5 101.921 46.5C101.313 46.5 100.821 46.9925 100.821 47.6C100.821 48.2075 101.313 48.7 101.921 48.7Z"
                      fill="#37307D"
                    />
                    <path
                      d="M108.521 48.7C109.128 48.7 109.621 48.2075 109.621 47.6C109.621 46.9925 109.128 46.5 108.521 46.5C107.913 46.5 107.421 46.9925 107.421 47.6C107.421 48.2075 107.913 48.7 108.521 48.7Z"
                      fill="#37307D"
                    />
                    <path
                      d="M108.521 36C109.128 36 109.621 35.5076 109.621 34.9001C109.621 34.2925 109.128 33.8 108.521 33.8C107.913 33.8 107.421 34.2925 107.421 34.9001C107.421 35.5076 107.913 36 108.521 36Z"
                      fill="#37307D"
                    />
                    <path
                      d="M108.521 42.4001C109.128 42.4001 109.621 41.9076 109.621 41.3001C109.621 40.6926 109.128 40.2001 108.521 40.2001C107.913 40.2001 107.421 40.6926 107.421 41.3001C107.421 41.9076 107.913 42.4001 108.521 42.4001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M108.521 48.7C109.128 48.7 109.621 48.2075 109.621 47.6C109.621 46.9925 109.128 46.5 108.521 46.5C107.913 46.5 107.421 46.9925 107.421 47.6C107.421 48.2075 107.913 48.7 108.521 48.7Z"
                      fill="#37307D"
                    />
                    <path
                      d="M82.4208 36C83.0283 36 83.5208 35.5076 83.5208 34.9001C83.5208 34.2925 83.0283 33.8 82.4208 33.8C81.8133 33.8 81.3208 34.2925 81.3208 34.9001C81.3208 35.5076 81.8133 36 82.4208 36Z"
                      fill="#37307D"
                    />
                    <path
                      d="M82.4208 42.4001C83.0283 42.4001 83.5208 41.9076 83.5208 41.3001C83.5208 40.6926 83.0283 40.2001 82.4208 40.2001C81.8133 40.2001 81.3208 40.6926 81.3208 41.3001C81.3208 41.9076 81.8133 42.4001 82.4208 42.4001Z"
                      fill="#37307D"
                    />
                    <path
                      d="M82.4208 48.7C83.0283 48.7 83.5208 48.2075 83.5208 47.6C83.5208 46.9925 83.0283 46.5 82.4208 46.5C81.8133 46.5 81.3208 46.9925 81.3208 47.6C81.3208 48.2075 81.8133 48.7 82.4208 48.7Z"
                      fill="#37307D"
                    />
                    <path
                      d="M88.8207 48.7C89.4282 48.7 89.9207 48.2075 89.9207 47.6C89.9207 46.9925 89.4282 46.5 88.8207 46.5C88.2132 46.5 87.7207 46.9925 87.7207 47.6C87.7207 48.2075 88.2132 48.7 88.8207 48.7Z"
                      fill="#37307D"
                    />
                    <path
                      d="M75.021 26.1C75.021 24.9 76.221 23.9 77.721 23.9H113.321C114.821 23.9 116.021 24.9 116.021 26.1V53.2H75.021V26.1Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M75.021 29.3H115.921"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M149.221 58.7H136.321L134.021 50.2H151.421L149.221 58.7Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M138.321 42.4C143.621 43.6 142.821 50.3 142.821 50.3C142.821 50.3 142.521 36 148.921 36"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="title">Удобный график</div>
                <div class="txt">
                  Вождение с 7:00 до 20:00 в любой день недели, включая сб. и вс.
                </div>
              </div>
              <div class="card">
                <div class="svg">
                  <svg
                    width="171"
                    height="70"
                    viewBox="0 0 171 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M66.5409 56.89L62.4909 46.25L58.4409 56.89"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M60.001 53.38H64.981"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M56.5107 56.89H68.1707"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M90.411 56.89L86.361 46.25L82.311 56.89"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M83.8711 53.38H88.8511"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M80.3911 56.89H92.0511"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M50.4409 19.4301V58.6001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M48.3607 14.44C47.4407 15.79 47.7907 17.62 49.1307 18.53L54.0607 21.89L57.3807 17.02L52.4507 13.66C51.1107 12.75 49.2807 13.1 48.3607 14.44Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M57.3809 17.03L60.2109 16.5"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.0605 21.9L54.5905 24.73"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.5209 15.16L51.1909 20.03"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M147.561 56.1L153.661 58.66"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.9707 19.4301L120.741 44.8701"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M57.2305 23.4399L74.9805 58.6999"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M142.671 44.5L140.671 47H119.071C119.071 47 123.271 42.3 132.071 42.3C136.671 42.3 140.271 43.4 142.671 44.5Z"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M150.871 47V53.8L146.971 56.1H146.471C146.571 55.8 146.671 55.4 146.671 55C146.671 53 144.971 51.3 142.971 51.3C140.971 51.3 139.271 53 139.271 55C139.271 55.4 139.371 55.8 139.471 56.1H118.471C118.571 55.8 118.671 55.4 118.671 55C118.671 53 117.071 51.3 114.971 51.3C112.971 51.3 111.271 53 111.271 55C111.271 55.4 111.371 55.8 111.471 56.1H108.271V49.8C108.271 49.8 112.771 47 116.171 47H140.671L142.671 44.5C144.771 45.5 146.171 46.4 146.171 46.4L150.871 47Z"
                      fill="#F5DE4D"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M150.871 49.2001H148.271"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M128.871 50.3H125.971"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M118.671 55C118.671 55.4 118.571 55.8 118.471 56.1C117.971 57.6 116.571 58.6 114.971 58.6C113.371 58.6 111.971 57.5 111.471 56.1C111.371 55.7 111.271 55.4 111.271 55C111.271 53 112.871 51.3 114.971 51.3C116.971 51.3 118.671 53 118.671 55Z"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M146.571 55C146.571 55.4 146.471 55.8 146.371 56.1C145.871 57.6 144.471 58.6 142.871 58.6C141.271 58.6 139.871 57.5 139.371 56.1C139.271 55.7 139.171 55.4 139.171 55C139.171 53 140.771 51.3 142.871 51.3C144.971 51.3 146.571 53 146.571 55Z"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M160.421 58.7H10.4209"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M37.471 46.4899C37.951 48.1899 36.691 49.7299 35.071 49.7299V54.2599C35.071 55.0099 34.471 55.6099 33.721 55.6099H19.881C19.131 55.6099 18.531 55.0099 18.531 54.2599V49.7299C16.901 49.7299 15.651 48.1999 16.121 46.5099C16.361 45.6399 17.121 44.9499 18.001 44.7699C18.301 44.7099 18.601 44.6999 18.911 44.7399C20.111 44.9299 20.731 46.0199 21.031 47.2199L21.711 51.4899C21.801 52.0399 22.271 52.4499 22.831 52.4499H30.761C31.321 52.4499 31.791 52.0399 31.881 51.4899L32.561 47.2199C32.821 45.8899 33.481 44.9199 34.691 44.7399C34.991 44.6999 35.281 44.6999 35.581 44.7599C36.471 44.9399 37.221 45.6199 37.471 46.4899Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M34.6911 44.74C33.4811 44.92 32.8311 45.86 32.5611 47.22L31.8811 51.49C31.7911 52.04 31.3211 52.45 30.7611 52.45H22.8311C22.2711 52.45 21.8011 52.04 21.7111 51.49L21.0311 47.22C20.7411 46.05 20.1111 44.93 18.9111 44.74C19.9711 41.38 23.0911 38.95 26.8011 38.95C30.5111 38.95 33.6311 41.38 34.6911 44.74Z"
                      fill="#F5DE4D"
                      stroke="#38337A"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.2109 55.9399V57.7099"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M32.3809 55.9399V57.7099"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="title">Современный автодром</div>
                <div class="txt">
                  Удобное месторасположение, комфортные условия: освещение, зона
                  отдыха, санузел
                </div>
              </div>
              <div class="card">
                <div class="svg">
                  <svg
                    width="171"
                    height="70"
                    viewBox="0 0 171 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M79.0852 44.3101C86.9152 44.3101 90.6452 48.4901 90.6452 48.4901H71.5352L69.5752 46.4001C71.7952 45.4201 74.9052 44.3101 79.0852 44.3101Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M66.6253 48.05C66.6253 48.05 67.6953 47.27 69.5653 46.4L71.5253 48.49H93.6453C96.6753 48.49 100.425 51.01 100.425 51.01V56.49H97.5753C97.6653 56.23 97.7553 55.88 97.7553 55.53C97.7553 53.79 96.2353 52.31 94.4553 52.31C92.5853 52.31 91.1553 53.79 91.1553 55.53C91.1553 55.88 91.2453 56.23 91.3353 56.49H72.6053C72.6953 56.23 72.7853 55.88 72.7853 55.53C72.7853 53.79 71.2653 52.31 69.4853 52.31C67.7053 52.31 66.1853 53.79 66.1853 55.53C66.1853 55.88 66.2753 56.23 66.3653 56.49H65.9153L62.4353 54.49V48.58L66.6253 48.05Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M84.4353 51.4901H81.4353"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M94.2854 52.2101C96.1554 52.2101 97.5754 53.7201 97.5754 55.5001C97.5754 55.8601 97.4854 56.1201 97.3954 56.4801C96.9554 57.7201 95.7054 58.7001 94.2854 58.7001C92.8654 58.7001 91.6154 57.8101 91.1754 56.4801C91.0854 56.2101 90.9954 55.8601 90.9954 55.5001C90.9954 53.7201 92.5054 52.2101 94.2854 52.2101Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M69.4753 52.2101C71.3453 52.2101 72.7653 53.7201 72.7653 55.5001C72.7653 55.8601 72.6753 56.1201 72.5853 56.4801C72.1453 57.7201 70.8953 58.7001 69.4753 58.7001C68.0553 58.7001 66.8053 57.8101 66.3653 56.4801C66.2753 56.2101 66.1853 55.8601 66.1853 55.5001C66.1853 53.7201 67.6153 52.2101 69.4753 52.2101Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M44.5053 20.27H30.1953V58.7H44.5053V20.27Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M160.195 58.7H10.1953"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M30.1953 37.12H20.4653V58.7H30.1953V37.12Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.2354 29.12H44.5054V58.7H54.2354V29.12Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M137.765 45.45H124.515V58.7H137.765V45.45Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M152.075 29.12H137.765V58.7H152.075V29.12Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M37.3452 26.5699V30.5699"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M37.3452 36.1899V44.6299"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="5.62 5.62"
                    />
                    <path
                      d="M37.3452 47.4399V51.4399"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M144.925 39.1V48.72"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-dasharray="10 10"
                    />
                    <path
                      d="M119.955 44.2801H101.475L99.1453 42.2401L101.475 40.1901H119.955L122.285 42.2401L119.955 44.2801Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M110.715 44.28V58.7"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M110.715 37.55V40.19"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="title">Реальное обучение</div>
                <div class="txt">
                  Разнообразие маршрутов по всему городу +20км от его границ
                </div>
              </div>
              <div class="card">
                <div class="svg">
                  <svg
                    width="171"
                    height="70"
                    viewBox="0 0 171 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M35.2755 30.63C33.8355 30.86 32.7455 32.1 32.7455 33.59V42.5L31.9755 50.1C25.8455 50.1 18.1655 43.04 18.1655 43.04C18.1655 43.04 26.5555 31.47 35.2755 30.63Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M160.195 58.7H10.1953"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M149.095 29.9999C149.195 31.1999 148.295 32.2999 147.095 32.3999L146.195 32.4999C144.995 32.5999 143.895 31.6999 143.795 30.4999L143.495 27.3999L148.605 26.1899C148.715 26.4099 148.775 26.6499 148.795 26.8999L149.095 29.9999Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M153.695 58.6L150.595 54.5V49.3"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M147.495 58.7L150.595 54.5"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M132.395 42.7999L130.595 42.7"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M141.096 43.6L138.896 43.4"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M146.196 44.5L151.296 44.8C151.296 44.8 152.096 49.3 148.796 49.3H148.596C148.296 49.3 147.996 49.2 147.696 49.2C145.996 49 144.396 49.5 142.696 49.9L142.996 58.7H138.896V47.2L146.196 44.5Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M138.896 43.4001V58.7001H132.396V42.8L138.896 43.4001Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M147.595 35.6C148.795 35.2 149.695 35.7 149.995 36.5C150.595 38.6 151.595 42.5 151.395 44.8L146.295 44.5L145.795 37.7L147.595 35.6Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M148.795 49.4C149.395 49.4 149.995 49.4 150.595 49.4C152.295 49.3 154.095 48.9 154.995 47.9C157.095 45.8 155.295 37.9 155.295 37.9"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M136.495 42.8L143.095 41.1L147.495 35.6"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M140.735 22.95L150.605 24.0499"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M148.615 23.84V26.19H148.605L143.495 27.4L143.015 23.21"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M94.3253 42.5L92.6753 58.6H96.8553L99.0053 45.92L101.795 58.6H104.585L103.695 42.5H94.3253Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M94.3252 42.5V33.59C94.3252 31.98 95.5952 30.66 97.1952 30.59L100.535 30.45C102.235 30.38 103.655 31.73 103.665 33.43L103.705 42.5H94.3252Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M95.4751 23.49V21.84C95.4751 20.07 96.8651 18.67 98.6451 18.67C100.415 18.67 101.815 20.06 101.815 21.84V23.49C101.815 25.26 100.425 26.66 98.6451 26.66C96.8651 26.66 95.4751 25.26 95.4751 23.49Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M99.0254 33.8L100.245 39.2001L106.675 44.7001"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M32.7452 42.5L31.0952 58.6H35.2752L37.4252 45.92L40.2152 58.6H43.0052L42.1152 42.5H32.7452Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M32.7451 42.5V33.59C32.7451 31.98 34.0151 30.66 35.6151 30.59L38.9551 30.45C40.6551 30.38 42.0751 31.73 42.0851 33.43L42.1251 42.5H32.7451Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M33.8853 23.49V21.84C33.8853 20.07 35.2753 18.67 37.0553 18.67C38.8253 18.67 40.2253 20.06 40.2253 21.84V23.49C40.2253 25.26 38.8353 26.66 37.0553 26.66C35.2853 26.66 33.8853 25.26 33.8853 23.49Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M33.0452 32.28L28.5752 39.85L32.4252 45.63"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M41.4053 31.5L45.2353 38.49L42.3053 45.63"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M49.9355 17.37V21.08"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M49.9355 24.8C50.4878 24.8 50.9355 24.3523 50.9355 23.8C50.9355 23.2478 50.4878 22.8 49.9355 22.8C49.3833 22.8 48.9355 23.2478 48.9355 23.8C48.9355 24.3523 49.3833 24.8 49.9355 24.8Z"
                      fill="#37307D"
                    />
                    <path
                      d="M133.305 24.7999C133.857 24.7999 134.305 24.3522 134.305 23.7999C134.305 23.2476 133.857 22.7999 133.305 22.7999C132.753 22.7999 132.305 23.2476 132.305 23.7999C132.305 24.3522 132.753 24.7999 133.305 24.7999Z"
                      fill="#37307D"
                    />
                    <path
                      d="M131.495 18.6699C131.495 18.6699 131.455 17.6599 132.435 17.3699C133.415 17.0799 135.115 17.4699 135.115 19.2099C135.115 20.9499 133.305 21.0799 133.305 21.0799"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M65.2554 58.7H50.9354C50.0554 57.24 50.0554 55.41 50.9354 53.95H65.2554C64.3754 55.41 64.3754 57.24 65.2554 58.7Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M66.1851 53.95H51.8651C50.9851 52.49 50.9851 50.66 51.8651 49.2H66.1851C65.3151 50.66 65.3151 52.49 66.1851 53.95Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M63.5054 49.2H49.1854C48.3054 47.74 48.3054 45.91 49.1854 44.45H63.5054C62.6254 45.91 62.6254 47.74 63.5054 49.2Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M65.9055 44.45H51.5955C50.7155 42.99 50.7155 41.16 51.5955 39.7H65.9155C65.0355 41.16 65.0355 42.99 65.9055 44.45Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="title">Сопровождение в ГИБДД</div>
                <div class="txt">
                  Трансфер до МРЭО, предоставление экзаменационных автомобилей,
                  помощь оф. представителя
                </div>
              </div>
              <div class="card">
                <div class="svg">
                  <svg
                    width="171"
                    height="70"
                    viewBox="0 0 171 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M160.195 58.7H10.1953"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M145.145 21.46L139.695 25.46L134.255 21.46L128.815 25.46L123.375 21.46L117.945 25.46L112.505 21.46V58.7H145.145V21.46Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M122.545 51.55C125.02 51.55 127.025 48.219 127.025 44.11C127.025 40.001 125.02 36.67 122.545 36.67C120.071 36.67 118.065 40.001 118.065 44.11C118.065 48.219 120.071 51.55 122.545 51.55Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M133.795 49.75V38.52C133.795 38.52 139.585 37.78 139.585 41.65C139.585 45.52 134.475 44.83 134.475 44.83"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M131.715 47.78H135.865"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.2051 23.6H70.7051V37.9"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.2051 58.8V23.6H34.6051"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M48.9053 44.6H42.9053V58.8"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M34.9053 26.6H59.0053V20.6H34.9053V26.6Z"
                      fill="#FFE03A"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M57.8052 40.5999H64.4052V32.7H57.8052V40.5999V40.5999Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M43.6055 40.5999H50.2055V32.7H43.6055V40.5999Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M29.5054 40.5999H36.1054V32.7H29.5054V40.5999V40.5999Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M29.5054 53.5999H36.1054V45.7H29.5054V53.5999V53.5999Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M70.7055 42.4C79.5055 42.4 83.7055 47.1 83.7055 47.1H62.1055L60.1055 44.6C62.5055 43.5 66.1055 42.4 70.7055 42.4Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M56.6053 46.5C56.6053 46.5 58.0053 45.6 60.1053 44.6L62.1053 47.1H86.6053C90.0053 47.1 94.5053 49.9 94.5053 49.9V56.2H91.3053C91.4053 55.9 91.5053 55.5 91.5053 55.1C91.5053 53.1 89.8053 51.4 87.8053 51.4C85.7053 51.4 84.1053 53.1 84.1053 55.1C84.1053 55.5 84.2053 55.9 84.3053 56.2H63.3053C63.4053 55.9 63.5053 55.5 63.5053 55.1C63.5053 53.1 61.8053 51.4 59.8053 51.4C57.8053 51.4 56.1053 53.1 56.1053 55.1C56.1053 55.5 56.2053 55.9 56.3053 56.2H55.8053L51.9053 53.9V47.1L56.6053 46.5Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.5053 49.3H51.9053"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M76.8053 50.4H73.9053"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M87.8055 51.4C89.9055 51.4 91.5055 53.1 91.5055 55.1C91.5055 55.5 91.4055 55.8 91.3055 56.2C90.8055 57.6 89.4055 58.7 87.8055 58.7C86.2055 58.7 84.8055 57.7 84.3055 56.2C84.2055 55.9 84.1055 55.5 84.1055 55.1C84.1055 53.1 85.8055 51.4 87.8055 51.4Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.9051 51.4C62.0051 51.4 63.6051 53.1 63.6051 55.1C63.6051 55.5 63.5051 55.8 63.4051 56.2C62.9051 57.6 61.5051 58.7 59.9051 58.7C58.3051 58.7 56.9051 57.7 56.4051 56.2C56.3051 55.9 56.2051 55.5 56.2051 55.1C56.2051 53.1 57.8051 51.4 59.9051 51.4Z"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M125.725 30.75H131.915"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="title">Бесплатное пробное занятие</div>
                <div class="txt">
                  Приходи на занятия и убедись лично в наших словах
                </div>
              </div>
            </div>
          </div>
          <div class="video-container" @click="showVideo">
            <div class="txt">
              Собственный автопарк
            </div>
            <!-- Желтая кнопка - play -->
            <div class="svg">
              <svg
                width="48"
                height="47"
                viewBox="0 0 48 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.0016 42.7738C34.7451 42.7738 43.4544 34.0691 43.4544 23.3312C43.4544 12.5933 34.7451 3.88855 24.0016 3.88855C13.2581 3.88855 4.54883 12.5933 4.54883 23.3312C4.54883 34.0691 13.2581 42.7738 24.0016 42.7738Z"
                  fill="#FFE03A"
                  stroke="#FFE03A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.1108 15.5542L31.7825 23.3313L20.1108 31.1083V15.5542Z"
                  fill="#FFE03A"
                  stroke="#37307D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="video"></div>
          </div>
        </div>
        <!-- Кнопка - оставить заявку -->
        <div class="why-bottom">
          <div @click="showModal" class="btn btn-yellow">
            Оставить заявку
            <div class="svg">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 9.58336C17.5029 10.6832 17.2459 11.7683 16.75 12.75C16.162 13.9265 15.2581 14.916 14.1395 15.6078C13.021 16.2995 11.7319 16.6662 10.4167 16.6667C9.31678 16.6696 8.23176 16.4126 7.25 15.9167L2.5 17.5L4.08333 12.75C3.58744 11.7683 3.33047 10.6832 3.33333 9.58336C3.33384 8.26815 3.70051 6.97907 4.39227 5.86048C5.08402 4.7419 6.07355 3.838 7.25 3.25002C8.23176 2.75413 9.31678 2.49716 10.4167 2.50002H10.8333C12.5703 2.59585 14.2109 3.32899 15.4409 4.55907C16.671 5.78915 17.4042 7.42973 17.5 9.16669V9.58336Z"
                  stroke="#151515"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Popup from "./Popup.vue";
import Video from "./Video.vue";
export default {
  name: "Why",
  props: {
    msg: String,
  },
  methods: {
    showModal() {
      this.$modal.show(
        Popup,
        {},
        {
          name: "popup",
          adaptive: true,
          minWidth: 300,
          maxWidth: 730,
          width: "100%",
          height: "auto",
        },
        {}
      );
    },
    showVideo() {
      this.$modal.show(
        Video,
        {},
        {
          name: "video",
          adaptive: true,
          maxWidth: 768,
          width: "100%",
          height: "auto",
        },
        {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#why {
  padding: 50px 0 71px;
  background: url("../assets/img/why-bg.png") no-repeat top center; //why-video.png
  background-size: cover;
  overflow: hidden;
}
.why-container {
  .title {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 1.2;
    color: #151515;
    max-width: 638px;
    width: 100%;
    margin-bottom: 40px;
    span {
      color: #37307d;
    }
  }
}
.why-center {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: 30px;
    width: 72%;
    // max-width: 900px;
    // width: 100%;
    // height: 376px;
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .card {
        //   max-width: 265px;
        width: 29%;
        box-sizing: border-box;
        margin-right: 35px;
        margin-bottom: 20px;
        .svg {
          width: 170px;
          height: 70px;
          margin-bottom: 10px;
        }
        .title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #37307d;
          margin-bottom: 10px;
        }
        .txt {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 19px;
          color: #151515;
        }
      }
    }
  }
  .video-container {
    position: relative;
    z-index: 1;
    width: 23%;
    height: 269px;
    margin-top: 20px;
    cursor: pointer;

    & > .txt {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      max-width: 143.88px;
      width: 100%;
      color: #37307d;
      margin-top: 50px;
      margin-bottom: 20px;
      z-index: 2;
    }
    & > .svg {
      width: 46px;
      height: 46px;
      cursor: pointer;
      z-index: 2;
    }
    & > .video {
      position: absolute;
      top: -25px;
      left: 0;
      overflow: hidden;
      border-radius: 50%;
      z-index: -1;
      opacity: 0.1;
      width: 100%;
      height: 269px;
      background: url("/static/img/video-bg-new.png") no-repeat 40% center;
      background-size: 412px 269px;
      transition: all 0.5s ease;
    }
    &:hover > .video {
      opacity: 0.4;
      transform: scale(1.1);
    }
  }
  .video-container:hover {
  }
}
.why-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-yellow {
    // max-width: 314px;
    // max-height: 56px;
  }
}

@media (max-width: 1300px) {
  .why-center {
    .cards .row .card {
      width: 42%;
    }
    .video-container {
      & > .video {
        border-radius: 80px;
        width: 160px;
      }
    }
  }
}
@media (max-width: 920px) {
  .why-center {
    flex-direction: column;
    .cards {
      width: 100%;
    }
    .video-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 250px;
      & > .txt {
        
        width: 100%;
        margin-bottom: 0;
        margin-top:0;
        margin-right: 20px;
        font-size: 20px;
        line-height: 1.2;
      }
      & > .video {
        top: 0;
        left: 15px;
        width: calc(100% - 15px);
        margin-left: auto;
        height: 100%;
        background: url("/static/img/video-bg-new.png") no-repeat center center;
        background-size: cover;
      }
      &:hover > .video {
        transform: none;
      }
    }
  }
}
@media (max-width: 500px) {
  .why-center {
    .cards {
      margin-right: 0;
      .row .card {
        width: 45%;
        margin-right: 0;
        .svg {
          width: 140px;
          height: 58px;
        }
      }
    }
    .video-container {
      height: 200px;
    }
  }
  .why-container > .title {
    font-size: 22px;
  }
}
</style>