<template>
  <section id="info">
    <div class="wrap">
      <div class="info-container">
        <!-- Верх блока -->
        <div class="title">Полезная информация</div>
        <div class="cards">
          <div class="col">
            <a href="https://союзавтошкол.рф/dopservices/actions/" class="card">
              <div class="img">
                <img src="../assets/img/info-card-1.png" alt="" />
              </div>
              <div class="content">
                <div class="txt">горячие акции и скидки на обучение</div>
                <div class="svg">
                  <svg
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="round"
                      d="M25.3647 45.8333C36.8766 45.8333 46.2089 36.5059 46.2089 25C46.2089 13.494 36.8766 4.16663 25.3647 4.16663C13.8528 4.16663 4.52051 13.494 4.52051 25C4.52051 36.5059 13.8528 45.8333 25.3647 45.8333Z"
                      fill="#FFE03A"
                    />
                    <path
                      class="arrow1"
                      d="M25.3647 33.3333L33.7024 25L25.3647 16.6666"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      class="arrow2"
                      d="M17.0269 25H33.7022"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://xn--80aenofndbuo1e6c.xn--p1ai/dopservices/"
              class="card"
            >
              <div class="img">
                <img src="../assets/img/info-card-4.png" alt="" />
              </div>
              <div class="content">
                <div class="txt">дополнительные услуги</div>
                <div class="svg">
                  <svg
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="round"
                      d="M25.3647 45.8333C36.8766 45.8333 46.2089 36.5059 46.2089 25C46.2089 13.494 36.8766 4.16663 25.3647 4.16663C13.8528 4.16663 4.52051 13.494 4.52051 25C4.52051 36.5059 13.8528 45.8333 25.3647 45.8333Z"
                      fill="#FFE03A"
                    />
                    <path
                      class="arrow1"
                      d="M25.3647 33.3333L33.7024 25L25.3647 16.6666"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      class="arrow2"
                      d="M17.0269 25H33.7022"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <a
              href="https://xn--80aenofndbuo1e6c.xn--p1ai/kakvibratavtoshkoly/sotrudniki/"
              class="card"
            >
              <div class="img">
                <img src="../assets/img/info-card-2.png" alt="" />
              </div>
              <div class="content">
                <div class="txt">преподавательский состав</div>
                <div class="svg">
                  <svg
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="round"
                      d="M25.3647 45.8333C36.8766 45.8333 46.2089 36.5059 46.2089 25C46.2089 13.494 36.8766 4.16663 25.3647 4.16663C13.8528 4.16663 4.52051 13.494 4.52051 25C4.52051 36.5059 13.8528 45.8333 25.3647 45.8333Z"
                      fill="#FFE03A"
                    />
                    <path
                      class="arrow1"
                      d="M25.3647 33.3333L33.7024 25L25.3647 16.6666"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      class="arrow2"
                      d="M17.0269 25H33.7022"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://xn--80aenofndbuo1e6c.xn--p1ai/photogallery/4/"
              class="card"
            >
              <div class="img">
                <img src="../assets/img/info-card-3.png" alt="" />
              </div>
              <div class="content">
                <div class="txt">наш автопарк</div>
                <div class="svg">
                  <svg
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="round"
                      d="M25.3647 45.8333C36.8766 45.8333 46.2089 36.5059 46.2089 25C46.2089 13.494 36.8766 4.16663 25.3647 4.16663C13.8528 4.16663 4.52051 13.494 4.52051 25C4.52051 36.5059 13.8528 45.8333 25.3647 45.8333Z"
                      fill="#FFE03A"
                    />
                    <path
                      class="arrow1"
                      d="M25.3647 33.3333L33.7024 25L25.3647 16.6666"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      class="arrow2"
                      d="M17.0269 25H33.7022"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://xn--80aenofndbuo1e6c.xn--p1ai/infospecrazdel/"
              class="card"
            >
              <div class="img">
                <img src="../assets/img/info-card-5.png" alt="" />
              </div>
              <div class="content">
                <div class="txt">уголок покупателя</div>
                <div class="svg">
                  <svg
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="round"
                      d="M25.3647 45.8333C36.8766 45.8333 46.2089 36.5059 46.2089 25C46.2089 13.494 36.8766 4.16663 25.3647 4.16663C13.8528 4.16663 4.52051 13.494 4.52051 25C4.52051 36.5059 13.8528 45.8333 25.3647 45.8333Z"
                      fill="#FFE03A"
                    />
                    <path
                      class="arrow1"
                      d="M25.3647 33.3333L33.7024 25L25.3647 16.6666"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      class="arrow2"
                      d="M17.0269 25H33.7022"
                      stroke="#37307D"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Info",
  props: {
    msg: String,
  },
};
</script>

<style lang="scss" scoped>
#info {
  padding: 60px 0 50px;
}
.info-container {
  width: 100%;
}
.title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 50px;
  color: #37307d;
}
.cards {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .card {
      width: 100%;
      // width: 366.19px;
      height: 188px;
      box-sizing: border-box;
      opacity: 0.9;
      border-radius: 10px;
      padding: 20px 30px;
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      overflow: hidden;
      & > ::before,
      & > ::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        z-index: -1;
        border-radius: 10px;
      }
      ::before {
        background: linear-gradient(
          180deg,
          rgba(55, 48, 125, 0) 0%,
          #37307d 66.67%,
          #37307d 100%
        );
      }
      ::after {
        opacity: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 224, 58, 0.7) 0%,
          #ffe03a 66.67%,
          #ffe03a 100%
        );
      }
      .img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: -2;
        img {
          object-fit: cover;
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .txt {
          font-family: "Roboto";
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.15;
          color: #f9f9f9;
          max-width: 250px;
          width: 100%;
          z-index: 1;
        }
        .svg {
          z-index: 1;
          width: 50px;
          height: 50px;
          flex-shrink: 0;
          & > svg {
            path {
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
    .card:hover {
      ::before {
        opacity: 0;
      }
      ::after {
        opacity: 0.9;
      }
      .content {
        .txt {
          color: #37307d;
        }
        .svg {
          & > svg path.arrow1,
          & > svg path.arrow2 {
            stroke: #fff;
          }

          & > svg path#round {
            stroke: #37307d;
            fill: #37307d;
          }
        }
      }
    }
  }
  .col:first-child {
    // width: 30px;
    width: 34%;
  }
  .col:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 63%;
    .card:not(:first-child) {
      margin-right: 0;
    }
    .card:not(:last-child) {
      width: 48%;
    }
    .card:last-child {
      width: 100%;
    }
  }
  .row:last-child {
    width: 100%;
    .card {
      height: 100%;
    }
  }
}
@media screen and (max-width: 1300px) {
  .cards {
    .col {
      .card {
        width: 100%;
        height: calc(50% - 30px);
      }
    }
    .col:first-child {
      width: 35%;
      .card {
      }
    }
    .col:last-child {
      width: 61%;
      flex-direction: column;
      justify-content: flex-start;
      .card:not(:last-child) {
        width: 60%;
        height: calc(50% - 30px);
      }
      .card:last-child {
        width: 35%;
        height: 100%;
        .content {
          flex-direction: column;
          height: 75%;
        }
      }
    }
  }
}

@media screen and (max-width: 838px) {
  #info{
    padding: 60px 0 0px;
  }
  .wrap {
    padding: 0 30px;
  }
  .cards {
    flex-direction: column;
    .col > .card {
      padding: 20px;
      margin-bottom: 15px;
    }
    .col > .card > .content {
      .txt {
        font-size: 15px;
      }
    }
    .col:first-child {
      width: 100%;
    }
    .col:last-child {
      width: 100%;
      .card:not(:last-child) {
        width: 100%;
      }
      .card:last-child {
        width: 100%;
        .content {
          flex-direction: row;
        }
      }
    }
  }
}
</style>