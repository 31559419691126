<template>
  <section id="navigation">
    <div class="wrap">
      <div class="navigation">
        <div class="nav">
          <a href="#why" class="nav-i">Почему мы</a>
          <a href="#about" class="nav-i">Наши преимущества</a>
          <a href="#tariffs" class="nav-i">Категории / Цены</a>
          <a href="#map" class="nav-i">Мы на карте</a>
          <a href="#info" class="nav-i">Полезная информация</a>
          <a href="#reviews" class="nav-i">Отзывы</a>
        </div>
        <!-- <a href="#" class="btn">
          <div class="img">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 2.5H16.3333C16.7754 2.5 17.1993 2.67559 17.5118 2.98816C17.8244 3.30072 18 3.72464 18 4.16667V15.8333C18 16.2754 17.8244 16.6993 17.5118 17.0118C17.1993 17.3244 16.7754 17.5 16.3333 17.5H13"
                stroke="#37307D"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.8335 14.1666L13.0002 9.99992L8.8335 5.83325"
                stroke="#37307D"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13 10H3"
                stroke="#37307D"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="txt">Войти в личный кабинет</div>
        </a> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    msg: String,
  },
};
</script>

<style lang="scss" scoped>
#navigation {
  background: #fff;
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav {
  display: flex;
  margin: 17px 0px;
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #37307d;
    margin-right: 30px;
  }
  a:last-child {
    margin-right: 0px;
  }
  a:hover {
    text-decoration-line: underline;
  }
}
.btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  .img {
    width: 20px;
    height: 20px;
  }
  .txt {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #37307d;
    margin-left: 10px;
  }
}
</style>